<template>
  <div>
    <Header @keywords="handleInfoFromHeader"></Header>
    <div class="wrapper">
      <div v-if="isMobile === false" style="height: 45vh; height: auto">
        <div class="centerWidth" style="margin: 20px auto">
          <div v-if="ad1">
            <a
              :href="ad1[trans('url,urlEn')]"
              target="_blank"
              :title="ad1[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 200px; vertical-align: top"
                :src="
                  ad1[trans('img,imgEn')]
                    ? baseURL + ad1[trans('img,imgEn')]
                    : ''
                "
                alt=""
              />
            </a>
          </div>
          <div style="margin-top: 20px">
            <el-tabs
              v-model="reportTabActive"
              @tab-click="handleClick('report')"
            >
              <el-tab-pane
                v-for="(item, index) in reportCates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
                :name="'report' + index"
              ></el-tab-pane>
            </el-tabs>
            <!-- <div v-if="reports.length">
              <div class="paperB">
                <div class="paperBox" v-for="item in reports" :key="item.id" @click="toDetail(item.id)">
                  <img
                    :src="item.img ? baseURL + item.img : ''"
                    alt=""
                    style="height: 184px"
                  />
                  <div style="padding: 20px">
                    <p class="paperT1 twoLine">{{ item.title }}</p>
                    <p class="paperT2 threeLine">{{ item.digest }}</p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 9px;
                      "
                    >
                      <p class="paperPage">
                        <i class="el-icon-document"></i
                        > {{ item.page + " " + $t("report.pages") }}
                      </p>
                      <p class="paperLook">{{ $t("report.viewReport") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 1160px; margin: 20px auto; text-align: center">
                <p class="more" @click="toListPage('report')">
                  {{ $t("report.moreReports") }}
                </p>
              </div>
            </div> -->
            <div v-if="reports.length">
              <div style="display: flex; flex-wrap: wrap; margin: 10px 0">
                <div class="paperMa" v-for="item in reports" :key="item.id" @click="toDetail(item.id)">
                  <div class="paperMBg">
                    <img
                      style="width: 100%; height: 100%"
                      :src="item.img ? baseURL + item.img : ''"
                    />
                  </div>
                  <div class="paperMBox">
                    <p class="paperMBoxT1 twoLine">{{ item.title }}</p>
                    <p class="paperMBoxT2 threeLine">{{ item.digest }}</p>
                  </div>
                </div>
              </div>
              <div style="width: 1160px; margin: 20px auto; text-align: center">
                <p @click="toListPage('report')" class="more">
                  {{ $t("report.moreReports") }}
                </p>
              </div>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>
          </div>
        </div>
        <div class="centerWidth" style="margin: 20px auto">
          <div v-if="ad2">
            <a
              :href="ad2.url"
              target="_blank"
              :title="ad2[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 200px; vertical-align: top"
                :src="
                  ad2[trans('img,imgEn')]
                    ? baseURL + ad2[trans('img,imgEn')]
                    : ''
                "
                alt=""
              />
            </a>
          </div>
          <div style="margin-top: 20px">
            <el-tabs
              v-model="journalTabActive"
              @tab-click="handleClick('journal')"
            >
              <el-tab-pane
                v-for="(item, index) in journalCates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
                :name="'journal' + index"
              ></el-tab-pane>
            </el-tabs>
            <div v-if="journals.length">
              <div style="display: flex; flex-wrap: wrap; margin: 10px 0">
                <div class="paperMa" v-for="item in journals" :key="item.id" @click="toDetail(item.id)">
                  <div class="paperMBg">
                    <img
                      style="width: 100%; height: 100%"
                      :src="item.img ? baseURL + item.img : ''"
                    />
                  </div>
                  <div class="paperMBox">
                    <p class="paperMBoxT1 twoLine">{{ item.title }}</p>
                    <p class="paperMBoxT2 threeLine">{{ item.digest }}</p>
                  </div>
                </div>
              </div>
              <div style="width: 1160px; margin: 20px auto; text-align: center">
                <p @click="toListPage('journal')" class="more">
                  {{ $t("report.moreJournals") }}
                </p>
              </div>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>
          </div>
        </div>
        <!-- <div
            v-if="change === 0"
            class="totalPage"

        >
          <div class="news">
            <div class="secondery">
              <li
                  v-for="(seconderyClassfys, index) in seconderyClassfy"
                  :key="index"
              >
                <div style="display: flex">
                  <el-button
                      :class="
                    seconderyClassfys.buttonStyle
                      ? 'buttonStyle21'
                      : 'buttonStyle11'
                  "
                      @click="sendSecondery(seconderyClassfys)"
                  >
                    <i class="el-icon-location"></i>
                    <span v-if="languageSelect === 'zh'">{{
                        seconderyClassfys.title
                      }}</span>
                    <span
                        v-if="languageSelect === 'en'"
                        style="font-size: 14px"
                    >{{ seconderyClassfys.titleEn }}</span
                    >
                  </el-button>

                  <el-tooltip effect="dark" content="订阅" v-if="languageSelect==='zh'" placement="top">
                    <el-button
                        v-if="seconderyClassfys.isSubscribe === 0"
                        class="subscribe1"
                        @click="subscribeThis(seconderyClassfys)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="取消订阅" v-if="languageSelect==='zh'" placement="top">
                    <el-button
                        v-if="seconderyClassfys.isSubscribe === 1"
                        class="subscribeDeny1"
                        @click="subscribeThis(seconderyClassfys)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="subscribe" v-if="languageSelect==='en'" placement="top">
                    <el-button
                        v-if="seconderyClassfys.isSubscribe === 0"
                        class="subscribe1"
                        @click="subscribeThis(seconderyClassfys)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="unsubscribe" v-if="languageSelect==='en'" placement="top">
                    <el-button
                        v-if="seconderyClassfys.isSubscribe === 1"
                        class="subscribeDeny1"
                        @click="subscribeThis(seconderyClassfys)"
                    ></el-button>
                  </el-tooltip>
                </div>
              </li>
            </div>
            <div
                style="
              min-height: 75vh;
              height: auto;
              padding-top: 5px;
              background-color: white;
              margin-left: 2%;
              width: 100%;
            "
            >
              <div style="width: 95%; min-height: 75vh; margin-left: 2%">
                <div class="list1" v-if="articles.length">
                  <div
                      class="item"
                      v-for="(article, index) in articles"
                      :key="index"
                  >
                    <div class="pdf" v-if="languageSelect === 'zh'">
                     
                      <div @click="detailPdf(article)">
                        <img
                            class="logoFdf"
                            :src="article.img"
                            alt="Article Image"
                        />
                      </div>
                      
                      <div class="detail">
                        <div class="title-tooltip">
                          <div
                              v-html="article.title"
                              @click="detailPdf(article)"
                              class="limitTitLe"
                          ></div>
                          <div class="tooltip-title" v-html="article.title"></div>
                        </div>

                        <div class="author-tooltip">
                          <div class="tooltip-trigger">
                            <img
                                src="../../assets/editor.png"
                                class="editor-icon"
                                alt="Editor Icon"
                            />
                            作者：<span class="author">{{ article.author }}</span>
                          </div>
                          <div class="tooltip-content">{{ article.author }}</div>
                        </div>

                        <div
                            style="
                          display: flex;
                          margin-top: 25px;
                          color: gray;
                          align-items: center;
                        "
                        >
                          <img
                              style="
                            width: 20px;
                            height: 20px;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                              src="../../assets/time.png"
                              alt=""
                          />
                          时间：<span style="margin-top: 2.5px">
                          {{ article.createTime }}</span
                        >
                        </div>
                        <div class="downLoad" @click="download(article)">
                          <img
                              src="@/assets/pdfIcon.png"
                              class="pdfIcon"
                              alt=""
                          />
                          <img
                              src="@/assets/pdfIconHover.png"
                              class="pdfIconHover"
                              alt=""
                          />
                          下载PDF
                        </div>
                      </div>
                    </div>
                    <div class="pdf" v-if="languageSelect === 'en'">
                      
                      <div @click="detailPdf(article)">
                        <img
                            class="logoFdf"
                            :src="article.img"
                            alt="Article Image"
                        />
                      </div>
                      
                      <div class="detail">
                        <div class="title-tooltip">
                          <div
                              v-html="article.title"
                              @click="detailPdf(article)"
                              class="limitTitLe"
                          ></div>
                          <div class="tooltip-title" v-html="article.title"></div>
                        </div>

                        <div class="author-tooltip">
                          <div class="tooltip-trigger">
                            <img
                                src="../../assets/editor.png"
                                class="editor-icon"
                                alt="Editor Icon"
                            />
                            Author：<span class="author">{{
                              article.author
                            }}</span>
                          </div>
                          <div class="tooltip-content">{{ article.author }}</div>
                        </div>

                        <div
                            style="
                          display: flex;
                          margin-top: 25px;
                          color: gray;
                          align-items: center;
                        "
                        >
                          <img
                              style="
                            width: 20px;
                            height: 20px;
                            font-size: 14px;
                            margin-right: 5px;
                          "
                              src="../../assets/time.png"
                              alt=""
                          />
                          Time：<span style="margin-top: 2.5px">
                          {{ article.createTime }}</span
                        >
                        </div>
                        <div class="downLoad" @click="download(article)">
                          <img
                              src="@/assets/pdfIcon.png"
                              class="pdfIcon"
                              alt=""
                          />
                          <img
                              src="@/assets/pdfIconHover.png"
                              class="pdfIconHover"
                              alt=""
                          />
                          Download PDF
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="articles.length !== 0" class="pagination-container">
                <el-pagination
                    @size-change="handleSizeChange"
                    v-model="page"
                    :current-page="page"
                    @current-change="handleCurrentChange"
                    :page-sizes="[6, 9, 12, 15]"
                    :page-size='size'
                    layout="sizes, prev, pager, next, jumper"
                    background
                    :total="total"
                >
                </el-pagination>
              </div>
            </div>

            <AiPop @change="showDialog"></AiPop>
          </div>
        </div> -->
      </div>
      <div v-if="isMobile === true">
        <div class="news1">
          <!-- <div v-if="ad1">
            <a
              :href="ad1.url"
              target="_blank"
              :title="ad1[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 120px; vertical-align: top"
                :src="
                  ad1[trans('img,imgEn')]
                    ? baseURL + ad1[trans('img,imgEn')]
                    : ''
                "
                alt=""
              />
            </a>
          </div> -->
          <div style="margin: 10px">
            <el-tabs
              v-model="reportTabActive"
              @tab-click="handleClick('report')"
            >
              <el-tab-pane
                v-for="(item, index) in reportCates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
                :name="'report' + index"
              ></el-tab-pane>
            </el-tabs>
            <!-- <div v-if="reports.length">
              <div class="chineseBox">
                <div class="chineseR" v-for="item in reports" :key="item.id" @click="toDetail(item.id)">
                  <img
                    :src="item.img ? baseURL + item.img : ''"
                    alt=""/>
                  <div class="m-report-txt-box">
                    <p class="reportTitle twoLine">{{ item.title }}</p>
                    <div>
                      <p class="chinesePage"><i class="el-icon-document"></i> {{ item.page + " " + $t("report.pages") }}</p>
                      <p class="chineseButton">{{ $t("report.viewReport") }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style=" display: flex; justify-content: center; align-items: center; ">
                <p class="chineseMore"  @click="toListPage('report')">{{ $t("report.moreReports") }}</p>
              </div>
            </div> -->
            <div v-if="reports.length">
              <div class="chineseBox" v-for="item in reports" :key="item.id" style="cursor: pointer"  @click="toDetail(item.id)">
                <div class="chineseR">
                  <img
                    :src="item.img ? baseURL + item.img : ''"
                    alt=""/>
                  <div>
                    <p class="reportTitle twoLine">{{ item.title }}</p>
                    <div>
                      <p class="chinesePage"><i class="el-icon-document"></i> {{ item.page + " " + $t("report.pages") }}</p>
                      <p class="chineseButton">{{ $t('common.view') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style=" display: flex; justify-content: center; align-items: center; ">
                <p class="chineseMore"  @click="toListPage('report')">{{ $t("report.moreReports") }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="news1">
          <!-- <div v-if="ad2">
            <a
            :href="ad2[trans('url,urlEn')]"
              target="_blank"
              :title="ad2[trans('name,titleEn')]"
            >
              <img
                style="width: 100%; height: 120px; vertical-align: top"
                :src="
                  ad2[trans('img,imgEn')]
                    ? baseURL + ad2[trans('img,imgEn')]
                    : ''
                "
                alt=""
              />
            </a>
          </div> -->
          <div style="margin: 10px">
            <el-tabs
              v-model="journalTabActive"
              @tab-click="handleClick('journal')"
            >
              <el-tab-pane
                v-for="(item, index) in journalCates"
                :key="item.id"
                :label="item[trans('title,titleEn')]"
                :name="'journal' + index"
              ></el-tab-pane>
            </el-tabs>
            <div v-if="journals.length">
              <div class="chineseBox1" v-for="item in journals" :key="item.id" @click="toDetail(item.id)">
                <img class="journalBg" :src="item.img ? baseURL + item.img : ''" alt="" />
                <div class="journalBox">
                  <p class="journalT1 twoLine">{{ item.title }}</p>
                  <p class="journalT2 threeLine">{{ item.digest }}</p>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <p class="chineseMore"  @click="toListPage('journal')">{{ $t("report.moreJournals") }}</p>
              </div>
            </div>
          </div>
        </div>
        <AiPop @change="showDialog"></AiPop>
        <ScrollTop></ScrollTop>
      </div>
    </div>

    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <CancleTip
      :dialogVisible="cancleVisible"
      @hide="hide"
      @confirmDialog="confirmDialog"
    ></CancleTip>
    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
    <AiPop></AiPop>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import LoginTip from "@/components/LoginTip.vue";
import CancleTip from "@/components/CancleTip.vue";
import InfoTip from "@/components/InfoTip.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import mixins from "@/utils/mixins.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";

import { getAds, getCates, getReports } from "@/api/index";

export default {
  name: "Science",
  mixins: [mixins],
  components: {
    ScrollTop,
    Bottom,
    // BottomBanner,
    InfoTip,
    LoginTip,
    AiPop,
    Header,
    Footer,
    CancleTip,
  },
  data() {
    return {
      activeName: "first",
      user: this.$store.state.user,
      isMobile: false,
      dialogVisibleInfo: false,
      cancleVisible: false,
      cancleId: null,
      dialogVisible: false,
      change: 0,
      searchKeywords: "",
      seconderyClassfy: [],
      articles: [],
      size: 9,
      page: 1,
      total: 1,
      token: localStorage.getItem("access_token"),
      userId: "",
      languageSelect: "",
      primaryTypeid: "2",

      ad1: null,
      ad2: null,
      reportCates: [],
      journalCates: [],
      reports: [],
      journals: [],
      reportTabActive: "report0",
      journalTabActive: "journal0",
    };
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    //   获取新闻二级分类列表
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");

    const currentPage = localStorage.getItem("setPage");
    if (currentPage) {
      try {
        const parsedPage = JSON.parse(currentPage);
        console.log(parsedPage);
        if (parsedPage.primaryTypeid === this.primaryTypeid) {
          this.page = parseInt(parsedPage.setPage);
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
        } else {
          this.page = 1;
          // console.log(error)
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
        }
      } catch (error) {
        console.log(error);
        localStorage.setItem("Title", "");
        localStorage.setItem("setPage", ""); // 清空 localStorage
        // 处理 JSON 解析错误
        this.page = 1; // 或者其他处理逻辑
      }
    } else {
      this.page = 1;
    }

    // this.classfy();
    // this.getartical();
    // this.handleScroll();

    this.getAds();

    await this.getCates(2);
    await this.getCates(1);

    this.getReports("report");
    this.getReports("journal");

    localStorage.setItem("Title", "");
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    async getAds() {
      let res = await getAds({
        positions: "5,6",
        status: 0,
      });
      this.ad1 = res.data.find((item) => item.position == 5);
      this.ad2 = res.data.find((item) => item.position == 6);
    },
    async getCates(id) {
      let res = await getCates({
        level: 2,
        parentId: id,
      });
      this[id == 1 ? "journalCates" : "reportCates"] = res.data;
    },
    async getReports(type) {
      let res = await getReports({
        secondaryType:
          this[`${type}Cates`][this[`${type}TabActive`].substr(-1, 1)].id,
        pageNum: 1,
        pageSize: type == 'report' ? (this.isMobile ? 8 : 12) : (this.isMobile ? 6 : 10),
        draftStatus: 0,
        articleFlag: 1,
      });
      this[`${type}s`] = res.rows;
    },
    toListPage(type) {
      let { parentId, id } =
        this[`${type}Cates`][this[`${type}TabActive`].substr(-1, 1)];
      this.$router.push({
        path: `/report/${parentId}/${id}`,
      });
    },
    handleClick(type) {
      this.getReports(type);
    },
    toDetail(id){
      this.$router.push(`/report/${id}`)
    },
    
    

    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.cancleVisible = false;
    },
    confirmDialog() {
      this.$axios
        .get("/api/subscribe/delete/" + parseInt(this.cancleId.subscribeId), {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.cancleVisible = false;
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消成功",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancel successfully",
              showClose: false,
            });
          }
          if (res.data.msg === "操作成功") {
            this.$set(this.cancleId, "isSubscribe", 0);
          }
          this.classfy();
        })
        .catch((error) => {
          console.log(error);
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消失败，请稍后再试",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancellation failed, please try again later",
              showClose: false,
            });
          }
        });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getartical();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleScroll();
      this.getartical();
    },
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0);
    },
    // 详情PDF
    detailPdf(pdf) {
      console.log(pdf);
      localStorage.setItem("Title", pdf.title);

      if (this.user.userId) {
        if (this.$store.state.typeInfo == 0) {
          this.dialogVisibleInfo = true;
        } else {
          this.$router.push({
            path: "/pdfDetail",
            query: {
              currentPage: this.page,
              Typeid: 2,
              filePath: pdf.filePath,
            },
          });
        }
      } else {
        this.dialogVisible = true;
      }
      document.title = localStorage.getItem("Title");
    },
    // 下载
    download(article) {
      if (this.userId) {
        if (this.$store.state.typeInfo == 0) {
          this.dialogVisibleInfo = true;
        } else {
          let pdfUrl = article.filePath;
          if (pdfUrl) {
            let link = document.createElement("a");
            link.href = pdfUrl;
            link.download = article.title;
            link.click();
          } else {
            console.error("文章的PDF链接不存在");
          }
        }
      } else {
        this.dialogVisible = true;
      }
    },
    getFileName(url) {
      let name = "";
      if (url !== null && url !== "") {
        name = url.substring(url.lastIndexOf("/") + 1);
      } else {
        name = "无";
      }
      return name;
    },
    // 关键词
    handleInfoFromHeader(info) {
      this.searchKeywords = info.seachInput;
      this.getartical();
      // console.log("关键词"+this.searchKeywords)
    },
    sendSecondery(index) {
      // 记录被点击的按钮索引
      for (var i = 0; i < this.seconderyClassfy.length; i++) {
        this.seconderyClassfy[i].buttonStyle = false;
      }
      index.buttonStyle = true;
      // console.log('Clicked item at index:', index)
      this.searchKeywords = "";
      this.secondaryTypeid = index.id;
      this.page = 1;
      this.size = 9;
      this.getartical();
    },
    // 订阅
    subscribeThis(sub) {
      console.log(sub);
      if (this.user.userId) {
        if (sub.isSubscribe === 0) {
          this.$axios
            .post(
              "/api/subscribe/add",
              {
                primaryType: sub.parentId,
                secondaryType: sub.id,
                type: "2",
                userId: this.user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "订阅成功",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Subscribe successfully",
                  showClose: false,
                });
              }

              if (res.data.msg === "操作成功") {
                this.$set(sub, "isSubscribe", 1);
              }
              this.classfy();
              // console.log(res)
            })
            .catch((error) => {
              console.log(error);
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "取消失败，请稍后再试",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Cancellation failed, please try again later",
                  showClose: false,
                });
              }
            });
        } else {
          this.cancleId = sub;
          this.cancleVisible = true;
        }

        this.$forceUpdate();
      } else {
        this.dialogVisible = true;
      }
      //
      // console.log(this.seconderyClassfy)
    },

    list() {
      if (this.articles.length === 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
        for (var i = 0; i < this.articles.length; i++) {
          // 首图
          this.articles[i].img = this.$utils.baseURL + this.articles[i].img;

          this.articles[i].filePath =
            this.$utils.baseURL + this.articles[i].filePath;

          const currentDate = new Date(this.articles[i].createTime);
          const year = currentDate.getFullYear();
          const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          const day = ("0" + currentDate.getDate()).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;

          this.articles[i].createTime = formattedDate;
        }
      }
    },
  },
  watch: {
    "$store.state.user"(newValue) {
      this.user = newValue;
    },
  },
};
</script>


<style scoped lang="scss">
.totlePage {
  min-height: 45vh;
  height: auto;
  width: 1350px;
  margin: 0 auto;
}
@media (max-width: 1300px) {
  .totlePage {
    width: 1220px;
    margin: 0 auto;
  }
}
.limitTitLe {
  width: 140px;
  font-size: 18px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.author {
  width: 90px;
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.author :hover {
  color: var(--main-color);
}

.paper {
  //border: black 5px solid;
  height: 220px;
  display: flex;
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: white;
  padding: 10px;
}

.pdf {
  cursor: pointer; /* 鼠标变成手型 */
  border-radius: 16px;
  display: flex;
  height: 180px;
}

.pdf :hover {
  color: var(--main-color);
}

.logoFdf {
  border-radius: 16px;
  border: 1px solid black;
  margin: 1%;
  width: 115px;
  height: 170px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.logoFdf:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.detail {
  width: 60%;
  margin: 2% 5% 3% 5%;
  font-size: 14px;
}

.author-tooltip {
  margin-top: 15px;
  width: 200px;
  position: relative;
  display: inline-block;
}
.title-tooltip {
  width: 200px;
  position: relative;
  display: inline-block;
}
.tooltip-title {
  visibility: hidden;
  width: 250px;
  background-color: white;
  border: black 1px solid;
  color: #333333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0;
  margin-left: -60px; /* 将提示框居中 */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: translateX(-50%);
}

.title-tooltip:hover .tooltip-title {
  visibility: visible;
  opacity: 1;
}
.tooltip-content {
  visibility: hidden;
  width: 200px;
  background-color: white;
  border: black 1px solid;
  color: #333333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -175%;
  left: 25%;
  margin-left: -60px; /* 将提示框居中 */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
  transform: translateX(-50%);
}

.author-tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.editor-icon {
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin-right: 5px;
}

.buttonStyle11 {
  font-size: 16px;
  color: #333333;
  width: 180px;
  height: 45px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle21 {
  font-weight: bold;
  font-size: 18px;
  color: var(--main-color);
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}
.buttonStyle1 {
  font-size: 14px;
  color: #333333;
  width: 100%;
  margin: 5px;
  height: 25px;
  padding: 2px;
  border: none;
  box-shadow: none;
  text-align: center;
  border-radius: 0;
  border-right: #333333 2px solid;
}

.buttonStyle2 {
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
  width: 100%;
  height: 25px;
  padding: 2px;
  margin: 5px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  box-shadow: 4px 2px var(--main-color);
}
li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 3.5%;
  width: 93%;
}

.secondery {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  //border: black 1px solid;
}
.list1 {
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
  padding: 20px 10px;
  background-color: white;

  .item {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
  }
}
.list {
  display: block;
  padding: 2px 2px;
  margin: 5px 0 60px 0;
  overflow-x: hidden;
  background-color: white;
  min-height: 100vh;

  .downLoad {
    width: 60%;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: #f5f5f5;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;

    .pagination-container {
      position: sticky;
      margin-top: auto;
      bottom: 0;
      font-size: 14px;
      text-align: center;
      background-color: #fff;
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    img.pdfIconHover {
      display: none;
    }
  }
  .item {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 3px 0;
    margin: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .pdf {
      cursor: pointer; /* 鼠标变成手型 */
      border-radius: 16px;
      display: flex;
      height: 140px;
      font-size: 14px;
    }
    .logoFdf {
      position: absolute;
      border-radius: 16px;
      border: 1px solid black;
      margin: 1% 1% 1% 1%;
      width: 108px;
      font-size: 8px;
      height: 131px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .logoFdf:hover {
      transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
      transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    }

    .limitTitLe {
      width: 120%;
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .limitTitLe:hover {
      color: var(--main-color);
    }

    .detail {
      width: 100%;
      font-size: 14px;
      margin-left: 130px;
    }

    .author-tooltip {
      margin-top: 10px;
      width: 220px;
      position: relative;
      display: inline-block;
    }
    .author[data-v-321ccc00] {
      width: 70%;
      color: gray;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .editor-icon {
      width: 15px;
      height: 15px;
      font-size: 8px;
      margin-right: 5px;
    }
    .tooltip-content {
      visibility: hidden;
      width: 200px;
      background-color: white;
      border: black 1px solid;
      color: #333333;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: -175%;
      left: -5%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .title-tooltip {
      width: 200px;
      position: relative;
      display: inline-block;
    }

    .tooltip-title {
      visibility: hidden;
      width: 75%;
      background-color: white;
      border: black 1px solid;
      color: #333333;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 0;
      margin-left: -60px; /* 将提示框居中 */
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltip-title::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 25%;
      border-width: 3px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
      transform: translateX(-50%);
    }

    .title-tooltip:hover .tooltip-title {
      visibility: visible;
      opacity: 1;
    }
  }
}

.downLoad {
  width: 140px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #f5f5f5;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  img.pdfIconHover {
    display: none;
  }
}

.subscribe1 {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny1 {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.subscribe {
  position: absolute;
  background-size: 12px 12px; /* 设置背景图片大小 */
  border: none;
  width: 5px;
  margin: -10px 0 0 0;
  background-image: url("../../assets/subscribe.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  background-size: 12px 12px; /* 设置背景图片大小 */
  border: none;
  margin: -10px 0 0 -15px;
  background-image: url("../../assets/subscribeDeny.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}
.secondery1 {
  width: 100%;
  height: 45px;
  background-color: white;
  display: flex;
  justify-content: space-between; /* 水平分布，两端对齐 */
  align-items: center; /* 垂直居中 */
  font-weight: bold;
  color: black;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  text-align: center;
  //border: black 1px solid;
}
.secondery1 li {
  width: 100%; /* 填充父元素宽度 */
  /* 其他样式属性... */
}
.news1 {
  width: 100%;
  overflow-x: hidden;
}
.downLoad:hover {
  background: var(--main-color);
  color: #fff;

  img.pdfIcon {
    display: none;
  }

  img.pdfIconHover {
    display: block;
  }
}

.paperB {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.paperBox {
  width: 275px;
  height: 382px;
  overflow: hidden;
  margin: 0 15px 10px 0;
  background: #f2f7ff;
  border-radius: 2px;
  cursor: pointer;
}

.paperT1 {
  height: 48px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #262626;
  transition: all 0.3s;
  &:hover{
    color: var(--main-color);
  }
}

.paperT2 {
  margin-top: 10px;
  height: 66px;
  overflow: hidden;
  font-size: 14px;
  color: #8a9099;
  line-height: 22px;
}

.paperPage {
  font-size: 14px;
  color: #a1a8b3;
  line-height: 30px;
  // background: url(https://imgs.ebrun.com/master/2021/images/ico_report_page.png)
  //   no-repeat 0;
  background-size: 13px 16px;
  // padding-left: 17px;
}

.paperLook {
  width: 90px;
  height: 30px;
  background: #1a79ff;
  border-radius: 2px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.more {
  display: inline-block;
  height: 40px;
  font-size: 14px;
  color: #a1a8b3;
  line-height: 40px;
  border: 1px solid #a1a8b3;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  padding: 0 68px;
  &:hover{
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
}

.paperMa {
  position: relative;
  width: 570px;
  height: 215px;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  cursor: pointer;
}

.paperMBg {
  position: absolute;
  left: 20px;
  top: 0;
  width: 146px;
  height: 196px;
  overflow: hidden;
  border-radius: 2px;
}

.paperMBox {
  width: 100%;
  height: 185px;
  overflow: hidden;
  margin-top: 30px;
  padding-left: 186px;
  box-sizing: border-box;
  background: #f2f7ff;
  border-radius: 2px;
  padding-right: 50px;
}

.paperMBoxT1 {
  // height: 48px;
  overflow: hidden;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  line-height: 24px;
  transition: color .2s;
  &:hover{
    color: var(--main-color);
  }

}

.paperMBoxT2 {
  margin-top: 10px;
  // max-height: 66px;
  // overflow-y: auto;
  font-size: 14px;
  color: #8a9099;
  line-height: 22px;
}

// 移动端
// .chineseBox {
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-between;
// }
// .chineseR {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: 43%;
//   height: 220px;
//   overflow: hidden;
//   background: #f5f9ff;
//   border-radius: 4px;
//   margin: 0 0 15px 0;
//   & > img {
//     width: 100%;
//     height: 120px;
//   }
//   & > div {
//     padding: 8px;
//   }
// }

.reportTitle {
  font-size: 14px;
  color: #17181a;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  // width: 100%;
  height: 50px;
  margin-bottom: 8px;
  // overflow: hidden;
  // overflow: hidden; //超出盒子宽度内容，便溢出隐藏
  // text-overflow: ellipsis; //用...省略号显示
  // display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
  // -webkit-box-orient: vertical; //从上到下垂直排列子元素
  // -webkit-line-clamp: 2; //显示的行数
}

.chinesePage {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAA0CAYAAAD8H6qwAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAANAAAAAALyozoAAAB2UlEQVRoBe2ZvU7DMBDHzykb37DCOyBAIIFYkSisiEpUYuOh2KCtVFrGNm1VJiYehDdAbLUPuzSRZRpiJ8F1wVnqO1v2r/87XxKH1NvhGWN4h4hbYPEiQD5KBMrXV+cvOssGs4AUYAi4SBHCWrt/ogVqW0kZSsAiG4X1x/BY9k9rB9OcNn2IsESB9WrtztFP65L7ZgflATeVCyLbRbfV9eL5CbyXMDitVsqvsU9qzFzRmAVhmQEOHp66h7FPargDyqF4zq4AhUGj1T+QGMdNp0AFEd/cqyMcCWX3ZVjnQMdwCGtIcdhodfYiWDdBBR2HpQyGtWZ/V5jugo5ZYZ0RypXt7iwIWqcvxA0K8GxdUULIm6kw/KawaR00CMhtFljroa9elntc0e00VdU7mHVF0wCT+j1okjJZ/cY5mveNQGwksaEmuarNbRz6vG8E4kFdzKFNOBloDGq6QFHjjUGz1sEIOAp9ZOv+Gueobh3UBdAdZ6yo7sRFj/OgRStqnKO+jqaE4O/mqK+j/zb0KX/817rnZjP5OpqUA/55VFXG11FVEcWem13vQZXI5Ta9orklVCbwiiqC5Da9orklVCb49i1U6XfGnJ/QZzn4ty3z18Faxq8UtmCj079Pe7/MwOy4YJoAAAAASUVORK5CYII=)
    no-repeat left 10px;
  background-size: 8px 11px;
  font-size: 12px;
  color: #a1a8b3;
  line-height: 35px;
  padding-left: 12px;
}

.chineseButton {
  width: 56px;
  height: 24px;
  background: #1a79ff;
  border-radius: 0.06rem;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;
}

.chineseMore {
  padding: 15px 20px;
  display: inline-block;
  border: 1px solid #1a79ff;
  font-size: 12px;
  line-height: 100%;
  color: #1a79ff;
  border-radius: 0.1rem;
  text-align: center;
  margin: 10px 0;
}

.chineseBox1 {
  position: relative;
  width: 100%;
  height: 140px;
  margin-top: 30px;
  background: #f2f7ff;
  border-radius: 4px;
  &:first-child{
    margin-top: 24px;
  }
}

.journalBg {
  position: absolute;
  top: -18px;
  left: 10px;
  width: 78px;
  height: 115px;
}

.journalBox {
  padding-left: 30%;
  width: 70%;
  box-sizing: content-box;
  overflow: hidden;
}

.journalT1 {
  margin-top: 10px;
  // max-height: 50px;
  // overflow-x: hidden;
  // overflow-y: auto;
  font-size: 14px;
  color: #17181a;
  line-height: 1.5;
  font-weight: bold;
}

.journalT2 {
  margin-top: 10px;
  // max-height: 100px;
  // overflow-x: hidden;
  // overflow-y: auto;
  font-size: 12px;
  color: #737880;
  line-height: 1.5;
  font-weight: 400;
}

.m-report-txt-box{
  display: flex;
  flex-direction: column;
  & > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chinesePage{
      line-height: initial;
      background: none;
      padding-left: 0;
    }
  }
}



.chineseBox {
  display: flex;
}
.chineseR {
  width: 100%;
  // height: 120px;
  overflow: hidden;
  border-radius: 4px;
  // padding: 0 0 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid #f0f0f0;
  img {
    width: 90px;
    height: 120px;
    margin-right: 10px;
  }
  & > div {
    // padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    & > div{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .chinesePage{
    padding-left: 0;
    line-height: initial;
    background: none;
  }
}

</style>

