<template>
  <div>
    <Header @keywords="handleInfoFromHeader"  @handle-nav="navHandle"></Header>
    <div class="custom-opportunity-page">
      <div v-if="isMobile === false" class="totlePage">
        <div class="custom-filter-box">
          <div class="switch-box">
            <div style="padding: 10px 20px">{{ $t('opportunity.language') }}:</div>
            <span
              v-for="(item, index) in cates1"
              :key="index"
              @click="selectItem('1', index)"
              :class="{ active: selectedCates1Index == index }"
            >
              {{ item[trans("title,titleEn")] }}
            </span>
          </div>
          <div class="switch-box" v-if="!cates2Index">
            <div style="padding: 10px 20px">{{ $t('opportunity.type') }}:</div>
            <span
              v-for="(item, index) in cates2"
              :key="index"
              @click="selectItem('2', index)"
              :class="{ active: selectedCates2Index === index }"
            >
              {{
                item.categoryId
                  ? item[trans("title,titleEn")]
                  : $t("common.all")
              }}
            </span>
          </div>
        </div>
        <div class="sort-box">
          <div class="sort-btn">
            <div style="padding: 10px 20px">{{ $t('opportunity.sort') }}:</div>
            <div class="sort" :class="sort" @click="sortHandle">
              {{ $t('opportunity.timeSort') }}
            </div>
          </div>
          <div class="table-data-sum">
            {{ $t('opportunity.totalTip1') }}<span> {{ total }} </span>{{ $t('opportunity.totalTip2') }}
          </div>
          <!--  {{ $t('opportunity.totalTip2') }} -->
        </div>
        <div class="custom-table-box">
          <el-table :data="dataList">
            <el-table-column :label="$t('opportunity.announcement')" align="left">
              <template slot-scope="scope">
                <div class="table-title">
                  <span class="price" v-if="badge(scope.row.examine)">{{
                    badge(scope.row.examine)
                  }}</span>
                  <span class="oneLine" @click="toDetail(scope.row.id)">{{
                    scope.row.title
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="enterprise_name"
              :label="$t('opportunity.company')"
              width="360"
            >
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('opportunity.announceTime')" width="180">
            </el-table-column>
            <el-table-column :label="$t('opportunity.operate')" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="toDetail(scope.row.id)"
                  type="text"
                  size="small"
                  >{{ $t('opportunity.detail') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--        分页-->
          <div class="pagination-container">
            <el-pagination
              hide-on-single-page
              :pager-count="5"
              layout="prev, pager, next"
              :page-size="size"
              @current-change="handleCurrentChange"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
        <AiPop @change="showDialog"></AiPop>
        
        <!-- <div class="news">
          <div class="secondery">
            <li v-for="(seconderyClassfys, index) in seconderyClassfy" :key="index">
              <div style="display: flex">
                <el-button v-if="languageSelect==='zh'" :class="seconderyClassfys.buttonStyle? 'buttonStyle2': 'buttonStyle1'" @click="sendSecondery(seconderyClassfys)">
                  <i class="el-icon-location"></i>
                  <span   v-if="languageSelect==='zh'">{{ seconderyClassfys.title }}</span>
                </el-button>
                <el-button  v-if="languageSelect==='en'" :class="seconderyClassfys.buttonStyle? 'buttonStyle4': 'buttonStyle3'" @click="sendSecondery(seconderyClassfys)">
                  <i class="el-icon-location"></i>
                  <span >{{ seconderyClassfys.titleEn }}</span>
                </el-button>
              </div>
            </li>
          </div>
          <div class="list" v-if="tableData.length!==0">
            <div v-if="triple===true">
              <div v-if="languageSelect==='zh'">
                <el-tabs v-model="tripleValue" @tab-click="handleTripleClick()" type="card">
                  <el-tab-pane v-for="(triples, index) in tripleClassfy" :key="index" :label="triples.title" :name="triples.categoryId">
                    <div v-if="languageSelect==='zh'" style="min-height: 65vh;font-weight: bold">
                      <el-table
                          :data="tableData"
                          class="tableStyle"
                          :row-class-name="tableRowClassName"
                          @row-click="handleRowClick">
                        <el-table-column
                            prop="title"
                            label="标题"
                            style="text-align: left;">
                          <template slot-scope="scope">
                            <div v-html="scope.row.title" style="text-align: left"></div>
                          </template>
                          <template slot="header" >
                            <div class="custom-header">标题</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="date"
                            style="text-align: center"
                            width="200"
                            label="日期">
                          <template slot="header" >
                            <div class="custom-header">日期</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            style="text-align: center"
                            label="项目类型"
                            width="200">
                          <template slot="header" >
                            <div class="custom-header">项目类型</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="浏览量"
                            width="150">
                          <template slot-scope="scope">
                            <div class="scan">
                              <div class="scanImg"></div>
                              <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span> 
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div v-if="languageSelect==='en'" style="min-height: 70vh;font-weight: bold">
                      <el-table
                          :data="tableData"
                          class="tableStyle"
                          :row-class-name="tableRowClassName"
                          @row-click="handleRowClick">
                        <el-table-column
                            prop="titleEn"
                            label="titleEn"
                            style="text-align: left;">
                          <template slot-scope="scope">
                            <div v-html="scope.row.titleEn" style="text-align: left"></div>
                          </template>
                          <template slot="header" >
                            <div class="custom-header">Title</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="date"
                            style="text-align: center"
                            width="200"
                            label="Date">
                          <template slot="header" >
                            <div class="custom-header">Date</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            style="text-align: center"
                            label="Type"
                            width="200">
                          <template slot="header" >
                            <div class="custom-header">Type</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="Views"
                            width="150">
                          <template slot-scope="scope">
                            <div class="scan">
                              <div class="scanImg"></div>
                              <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div v-if="languageSelect==='en'">
                <el-tabs v-model="tripleValue" @tab-click="handleTripleClick()" type="card">
                  <el-tab-pane v-for="(triples, index) in tripleClassfy" :key="index" :label="triples.titleEn" :name="triples.categoryId">
                    <div v-if="languageSelect==='zh'" style="min-height: 65vh;font-weight: bold">
                      <el-table
                          :data="tableData"
                          class="tableStyle"
                          :row-class-name="tableRowClassName"
                          @row-click="handleRowClick">
                        <el-table-column
                            prop="title"
                            label="标题"
                            style="text-align: left;">
                          <template slot-scope="scope">
                            <div v-html="scope.row.title" style="text-align: left"></div>
                          </template>
                          <template slot="header" >
                            <div class="custom-header">标题</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="date"
                            style="text-align: center"
                            width="200"
                            label="日期">
                          <template slot="header" >
                            <div class="custom-header">日期</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            style="text-align: center"
                            label="项目类型"
                            width="200">
                          <template slot="header" >
                            <div class="custom-header">项目类型</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="浏览量"
                            width="150">
                          <template slot-scope="scope">
                            <div class="scan">
                              <div class="scanImg"></div>
                              <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div v-if="languageSelect==='en'" style="min-height: 70vh;font-weight: bold">
                      <el-table
                          :data="tableData"
                          class="tableStyle"
                          :row-class-name="tableRowClassName"
                          @row-click="handleRowClick">
                        <el-table-column
                            prop="titleEn"
                            label="titleEn"
                            style="text-align: left;">
                          <template slot-scope="scope">
                            <div v-html="scope.row.titleEn" style="text-align: left"></div>
                          </template>
                          <template slot="header" >
                            <div class="custom-header">Title</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="date"
                            style="text-align: center"
                            width="200"
                            label="Date">
                          <template slot="header" >
                            <div class="custom-header">Date</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            style="text-align: center"
                            label="Type"
                            width="200">
                          <template slot="header" >
                            <div class="custom-header">Type</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                            label="Views"
                            width="150">
                          <template slot-scope="scope">
                            <div class="scan">
                              <div class="scanImg"></div>
                              <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span> 
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div v-else>
              <div v-if="languageSelect==='zh'" style="min-height: 70vh;font-weight: bold">
                <el-table
                    :data="tableData"
                    class="tableStyle"
                    :row-class-name="tableRowClassName"
                    @row-click="handleRowClick">
                  <el-table-column
                      prop="title"
                      label="标题"
                      style="text-align: left;">
                    <template slot-scope="scope">
                      <div v-html="scope.row.title" style="text-align: left"></div>
                    </template>
                    <template slot="header" >
                      <div class="custom-header">标题</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="date"
                      style="text-align: center"
                      width="200"
                      label="日期">
                    <template slot="header" >
                      <div class="custom-header">日期</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="name"
                      style="text-align: center"
                      label="项目类型"
                      width="200">
                    <template slot="header" >
                      <div class="custom-header">项目类型</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="浏览量"
                      width="150">
                    <template slot-scope="scope">
                      <div class="scan">
                        <div class="scanImg"></div>
                        <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span> 
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="languageSelect==='en'" style="min-height: 70vh;font-weight: bold">
                <el-table
                    :data="tableData"
                    class="tableStyle"
                    :row-class-name="tableRowClassName"
                    @row-click="handleRowClick">
                  <el-table-column
                      prop="titleEn"
                      label="titleEn"
                      style="text-align: left;">
                    <template slot-scope="scope">
                      <div v-html="scope.row.titleEn" style="text-align: left"></div>
                    </template>
                    <template slot="header" >
                      <div class="custom-header">Title</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="date"
                      style="text-align: center"
                      width="200"
                      label="Date">
                    <template slot="header" >
                      <div class="custom-header">Date</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="name"
                      style="text-align: center"
                      label="Type"
                      width="200">
                    <template slot="header" >
                      <div class="custom-header">Type</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="Views"
                      width="150">
                    <template slot-scope="scope">
                      <div class="scan">
                        <div class="scanImg"></div>
                        <span> &nbsp; &nbsp;{{ scope.row.viewNum }}</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            
          </div>
          
          <div v-else style="width: 90%;min-height:45vh">
            <template v-if="languageSelect==='zh'" >
              <div class="notLogin">
                <img src="@/assets/none.png" alt="" />
                更多数据，等你发现
              </div>
            </template>
            <template v-if="languageSelect==='en'" >
              <div class="notLogin">
                <img src="@/assets/none.png" alt="" />
                More data waiting to be found
              </div>
            </template>
          </div>

          <AiPop @change="showDialog"></AiPop>
        </div> -->
      </div>
      <div v-if="isMobile === true">
        <div class="news1">
          <a :href="ad1[trans('url,urlEn')]" v-if="ad1" :title="ad1[trans('name,titleEn')]">
            <img
              class="commercialImage"
              :src="baseURL + ad1[trans('img,imgEn')]"
              alt=""
            />
          </a>
          <div style="">
            <div class="switch-box1">
              <div style="padding: 10px 20px; background: #e6ebf2">
                <p>{{ $t('opportunity.language') }}:</p>
              </div>
              <div>
                <span
                  v-for="(item, index) in cates1"
                  :key="index"
                  @click="selectItem('1', index)"
                  :class="{ active: selectedCates1Index == index }"
                >
                  {{ item[trans("title,titleEn")] }}
                </span>
              </div>
            </div>
            <div class="switch-box1" v-if="!cates2Index">
              <div style="padding: 10px 20px; background: #e6ebf2">{{ $t('opportunity.type') }}:</div>
              <span
                v-for="(item, index) in cates2"
                :key="index"
                @click="selectItem('2', index)"
                :class="{ active: selectedCates2Index === index }"
              >
                {{
                  item.categoryId
                    ? item[trans("title,titleEn")]
                    : $t("common.all")
                }}
              </span>
            </div>
            <div class="sort-box1">
              <span class="sortT1"></span>
              <span class="sortT2">{{ $t('index.opportunity') }}</span>
              <!-- <div class="sort-btn">
                <div style="padding: 10px 20px">排序:</div>
                <div class="sort" :class="sort" @click="sortHandle">
                  按信息发布时间
                </div>
              </div>
              <div class="table-data-sum">
                共<span>{{ total }}</span
                >条数据
              </div> -->
            </div>
            <div class="sortBox" v-for="item in dataList" :key="item.id">
              <div class="sortBoxHeader" @click="toDetail(item.id)">
                <span v-if="badge(item.examine)">{{ badge(item.examine) }}</span>
                <p class="oneLine">{{ item.title }}</p>
              </div>
              <div>
                <div class="sortBoxT">
                  <p>{{$t('opportunity.startTime')}}：<span>{{ item.start_time ? item.start_time.split(' ')[0] : '' }}</span></p>
                  <p>{{$t('opportunity.endTime')}}：<span>{{ item.end_time ? item.end_time.split(' ')[0] : '' }}</span></p>
                </div>
                <div class="sortBoxT1">
                  <p>{{$t('opportunity.enterpriseName')}}：<span>{{ item.enterprise_name }}</span></p>
                </div>
              </div>
            </div>
            <!--        分页-->
            <Pagination :size="size" @current-change="handleCurrentChange" :total="total"></Pagination>

          </div>
          <ScrollTop></ScrollTop>
          <AiPop @change="showDialog"></AiPop>
        </div>
      </div>
    </div>

    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import md5 from "js-md5";
import AiPop from "@/components/AiPop.vue";
import LoginTip from "@/components/LoginTip.vue";
import InfoTip from "@/components/InfoTip.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import mixins from "@/utils/mixins.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";
import Pagination from "@/components/mobile/Pagination.vue";

import { mapState } from "vuex";

import { getNews, getNewsCates, getAds } from "@/api/index";

export default {
  name: "Commercial",
  mixins: [mixins],
  components: {
    ScrollTop,
    Bottom,
    // BottomBanner,
    InfoTip,
    LoginTip,
    AiPop,
    Header,
    Footer,
    Pagination
  },
  props: {
    primaryTypeid: {
      type: [String, Number],
      default: 3,
    },
  },
  computed: {
    badge() {
      return (val) => {
        let result = "";
        if (this.cates2.length) {
          let temp = this.cates2.find((item) => item.categoryId == val);
          if (temp && temp.title) {
            result = temp.title.includes("招标")
              ? this.$t('opportunity.tender')
              : temp.title.includes("公示")
              ? this.$t('opportunity.rAnnouncement')
              : "";
          }
        }
        return result;
      };
    },
    sort() {
      return this.sortMark ? (this.sortMark == 1 ? "asc" : "desc") : "";
    },
    cates2Index(){
      console.log(this.cates1[this.selectedCates1Index])
      return this.cates1[this.selectedCates1Index] && this.cates1[this.selectedCates1Index].title.includes("英文")
    },
    ...mapState({
      access_token: "access_token",
      userInfo: "user",
    }),
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 20,
      triple: false,
      tripleValue: "",
      isMobile: false,
      dialogVisibleInfo: false,
      languageSelect: "",
      dialogVisible: false,
      newsId: "",
      translateThis: 1,
      searchKeywords: "",
      seconderyClassfy: [],
      tripleClassfy: [],
      articles: [],
      titleEn: "",
      contentEn: "",

      collect: 0,
      translatedetaiContent: "",
      detailTitle: "",
      detailTime: "",
      detailContent: "",
      translatedetailTitle: "",
      q: "",
      zhStr: "",
      enStr: "",
      salt: parseInt(Math.random() * 1000000000), //随机数
      appid: "20231016001849033",
      userkey: "ZgPv1FfEEWXPsNH0HzWl",
      token: "",
      userId: "",
      secondaryTypeid: "",
      isView: "",
      isWhole: "",
      user: this.$store.state.user,

      tableData: [],
      cates1: [],
      cates2: [],
      selectedCates1Index: 0,
      selectedCates2Index: 0,
      size: 25,
      page: 1,
      total: 1,
      dataList: [],
      sortMark: "",
      ad1: ''
    };
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    const currentPage = localStorage.getItem("setPage");
    // this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(this.userInfo.avatar)
    if (currentPage) {
      try {
        const parsedPage = JSON.parse(currentPage);
        if (parsedPage.primaryTypeid === this.primaryTypeid) {
          if (parseInt(parsedPage.setPage)) {
            this.page = parseInt(parsedPage.setPage);
          } else {
            this.page = 1;
          }
          localStorage.setItem("setPage", "");
          localStorage.setItem("Title", "");
        } else {
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
          this.page = 1;
        }
      } catch (error) {
        localStorage.setItem("Title", "");
        localStorage.setItem("setPage", ""); // 清空 localStorage
        // 处理 JSON 解析错误
        this.page = 1; // 或者其他处理逻辑
      }
    } else {
      this.page = 1;
    }

    await this.getNewsCates(2, this.primaryTypeid);
    await this.getNewsCates(3, this.cates1[this.selectedCates1Index].categoryId);

    if(!this.$route.params.cateId) {
      await this.getNews();
    }else{
      this.navHandle(this.$route.params)
    }
    await this.getAds();
    

    //   是否登录才可查看
    // this.loginView();
    //   获取新闻二级分类列表
    // this.classfy();
    // this.classfytriple();

    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);

    window.scrollTo(0, 0); // 例如滚动到顶部
  },
  methods: {
    async getNewsCates(level, parentId) {
      let res = await getNewsCates({
        level,
        parentId,
      });
      if (level == 3) {
        res.data.unshift({});
      }
      this["cates" + (level - 1)] = res.data;
    },
    async getNews(params) {
      let res = await getNews({
        secondaryTypeid: this.cates1[this.selectedCates1Index].categoryId || "",
        examine: this.cates2[this.selectedCates2Index].categoryId || "",
        pageNum: this.page,
        pageSize: this.size,
        draftStatus: 0,
        articleFlag: 1,
        isSort: 2,
        ...params,
      });
      this.dataList = res.rows;
      this.total = res.total;
    },
    selectItem(type, index) {
      this[`selectedCates${type}Index`] = index;

      if (type == 1) {
        this[`selectedCates2Index`] = 0;
      }

      this.getNews();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleScroll();
      this.getNews();
    },
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0); // 例如滚动到顶部
    },
    sortHandle() {
      this.sortMark = this.sortMark ? (this.sortMark == 1 ? 2 : "") : 1;
      this.getNews({
        isSort: this.sortMark != 1 ? 2 : 1,
      });
    },
    toDetail(id) {
      if (this.userInfo.userId) {
        this.$router.push("/opportunity/" + id);
      } else {
        this.dialogVisible = true;
      }
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    async getAds(){
      let res = await getAds({
        positions: '9'
      });
      this.ad1 = res.data[0]
    },
    navHandle(data){
      console.log(data)
      let index = this.cates1.findIndex(item => {
        return item.categoryId == data.cateId
      })
      this.selectItem('1', index)
    },



    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    // hideDialog() {
    //   this.dialogVisible = false;
    // },
    // change改变
    handleChildValue(value) {
      this.change = value;
    },
    handleSizeChange(val) {
      this.size = val;
      // this.getartical();
    },

    // 详情
    handleRowClick(artical) {
      // 在这里获取被点击行的信息
      this.detailTitle = artical.fanyititle;
      this.detailContent = artical.content;
      this.q = artical.content;
      this.detailTime = artical.date;
      this.newsId = artical.id;
      console.log("this.isView:", this.isView);
      console.log("this.user:", Object.keys(this.user).length === 0);
      console.log(this.user);
      // 不需要登录
      if (this.isView === "1") {
        this.$router.push({
          path: "/articalDetail",
          query: {
            currentPage: this.page,
            Typeid: artical.primaryTypeid,
            newId: artical.id,
          },
        });
      }

      // 需要登录
      else {
        // console.log("--------------",this.userInfo)
        if (this.userInfo !== null) {
          if (this.isWhole === "1") {
            this.$router.push({
              path: "/articalDetail",
              query: {
                currentPage: this.page,
                Typeid: artical.primaryTypeid,
                newId: artical.id,
              },
            });
          } else {
            if (this.$store.state.typeInfo == 0) {
              this.dialogVisibleInfo = true;
            } else {
              this.$router.push({
                path: "/articalDetail",
                query: {
                  currentPage: this.page,
                  Typeid: artical.primaryTypeid,
                  newId: artical.id,
                },
              });
            }
          }
        } else {
          this.dialogVisible = true;
        }
      }
    },

    //收藏
    collectChange() {
      if (this.collect === 1) {
        this.collect = 0;
      } else if (this.collect === 0) {
        this.collect = 1;
      }
    },
    // 翻译
    async translate() {
      if (this.translateThis === 1) {
        this.translateThis = 0;
        this.trans = true;
      } else {
        this.translateThis = 1;
        this.trans = false;
      }

      /* 从页面获取选择的目标语言 传入url */
      this.to = "en";
      /* 对待翻译字符做url编码 */
      this.from = "zh";
      var titlesalt = Math.floor(Math.random() * 10000).toString();
      var transtitle = md5(
        this.appid + this.detailTitle + titlesalt + this.userkey
      );
      /* 请求翻译 */

      await this.$axios
        .get(
          "/baidu" +
            "?q=" +
            this.detailTitle +
            "&from=" +
            this.from +
            "&to=" +
            this.to +
            "&appid=" +
            this.appid +
            "&salt=" +
            titlesalt +
            "&sign=" +
            transtitle
        )
        .then((res) => {
          this.translatedetailTitle = res.data.trans_result[0].dst;
        });
      var contentsalt = Math.floor(Math.random() * 10000).toString();
      var transcontent = md5(this.appid + this.q + contentsalt + this.userkey);
      await this.$axios
        .get(
          "/baidu" +
            "?q=" +
            this.q +
            "&from=" +
            this.from +
            "&to=" +
            this.to +
            "&appid=" +
            this.appid +
            "&salt=" +
            contentsalt +
            "&sign=" +
            transcontent
        )
        .then((res) => {
          this.translatedetaiContent = res.data.trans_result[0].dst;
        });
    },
    // 关键词
    handleInfoFromHeader(info) {
      this.searchKeywords = info.seachInput;
      // this.getartical();
      // console.log("关键词"+this.searchKeywords)
    },
    tableRowClassName({ rowIndex }) {
      // if (rowIndex === 1) {
      //   return 'warning-row';
      // } else if (rowIndex === 3) {
      //   return 'success-row';
      // }
      return `row-${rowIndex}`; // 这里使用了动态类名
    },
    sendSecondery(index) {
      // 记录被点击的按钮索引
      for (var i = 0; i < this.seconderyClassfy.length; i++) {
        this.seconderyClassfy[i].buttonStyle = false;
      }
      index.buttonStyle = true;
      // console.log('Clicked item at index:', index)
      this.searchKeywords = "";
      this.secondaryTypeid = index.categoryId;
      if (this.secondaryTypeid == 15) {
        this.triple = true;
        this.tripleValue = 24;
      } else {
        this.triple = false;
        this.tripleValue = "";
      }
      this.page = 1;
      this.size = 8;
      // this.getartical();
    },
    // 订阅
    subscribeThis(sub) {
      if (this.userId) {
        if (sub.isSubscribe === 0) {
          this.$set(sub, "isSubscribe", 1);
          this.$axios
            .post(
              "/api/subscribe/add",
              {
                primaryType: 3,
                secondaryType: sub.categoryId,
                type: "2",
                userId: this.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$set(sub, "isSubscribe", 0);
        }
        this.$forceUpdate();
      } else {
        this.dialogVisible = true;
      }
    },
    // 获取二级分类
    classfy() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 2,
            parentId: this.primaryTypeid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.seconderyClassfy = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组
          for (var i = 0; i < this.seconderyClassfy.length; i++) {
            this.seconderyClassfy[i].buttonStyle = false;
            if (
              this.languageSelect === "zh" &&
              this.seconderyClassfy[i].categoryId == 15
            ) {
              this.sendSecondery(this.seconderyClassfy[i]);
            } else if (
              this.languageSelect === "en" &&
              this.seconderyClassfy[i].categoryId == 22
            ) {
              this.sendSecondery(this.seconderyClassfy[i]);
            }
          }
          if (this.$route.query.TypeId) {
            for (var j = 0; j < this.seconderyClassfy.length; j++) {
              if (
                this.seconderyClassfy[j].categoryId ===
                parseInt(this.$route.query.TypeId)
              ) {
                var index = this.seconderyClassfy[j];
                this.sendSecondery(index);
                break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 三级分类
    classfytriple() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 3,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.tripleClassfy = res.data.data;
          this.tripleValue = this.tripleClassfy[0].categoryId;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleTripleClick() {
      // this.getartical();
    },
    loginView() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
            categoryId: 3,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isView = res.data.data[0].isView;
          this.isWhole = res.data.data[0].isWhole;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getartical() {
      this.$axios
        .get("/api/news/list", {
          params: {
            primaryTypeid: 3,
            secondaryTypeid: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
            draftStatus: 0,
            articleFlag: 1,
            examine: this.tripleValue,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.total = res.data.total;
          this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组

          if (this.articles.length === 0) {
            this.tableData = [];
          }
          // 重新生成列表
          // this.list();
        })
        .catch((error) => {
          console.log(error);
        });
      // 动态调整页面高度
      this.adjustPageHeight();
    },
    adjustPageHeight() {
      // 使用setTimeout确保Vue有时间更新页面内容
      // this.$nextTick(() => {
      //   const contentHeight = this.$refs.content.scrollHeight;
      //   const listElement = this.$refs.list;
      //
      //   if (contentHeight > listElement.clientHeight) {
      //     listElement.style.height = contentHeight + 'px';
      //   }
      // });
    },
    async list() {
      if (this.articles.length === 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
        this.tableData = [];
        for (var i = 0; i < this.articles.length; i++) {
          const currentDate = new Date(this.articles[i].createTime);
          const year = currentDate.getFullYear();
          const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          const day = ("0" + currentDate.getDate()).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;
          this.articles[i].fanyi = this.stripHtmlTags(this.articles[i].content);
          if (this.articles[i].fanyi.length > 150) {
            const truncatedText =
              this.articles[i].fanyi.slice(0, this.maxTextLength) + "…";
            this.articles[i].truncatedContent = truncatedText; // 保存截断后的内容到新字段
          } else {
            this.articles[i].truncatedContent = this.articles[i].fanyi; // 如果没有截断，保存原始内容
          }
          this.articles[i].leighttitle = this.articles[i].title.replace(
            new RegExp(this.searchKeywords, "gi"),
            (match) => `<span style="color: var(--main-color)">${match}</span>`
          );
          this.articles[i].scan = Math.floor(Math.random() * 9000) + 1000;
          this.articles[i].agree = Math.floor(Math.random() * 9000) + 1000;
          this.articles[i].createTime = formattedDate;
          var vm = this;
          await vm.$axios
            .post("/api/enes/trans", {
              // params: {
              newsId: vm.articles[i].id,
              // },
              headers: {
                Authorization: vm.token,
              },
            })
            .then((res) => {
              // console.log(res.data.data.enTitle)
              vm.titleEn = res.data.data.enTitle;
              vm.contentEn = res.data.data.enContent;
            })
            .catch((error) => {
              console.log(error);
            });
          this.articles[i].titleEn = this.titleEn;
          let newContent = this.articles[i].content.replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            (match, p1) => {
              let imgSrc =
                p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
              // console.log(this.articles[i].contentImg)
              // 获取第一张图片
              if (this.articles[i].contentImg === undefined) {
                this.articles[
                  i
                ].contentImg = `<img style="text-align: center;width:100%;height:100%" src='${imgSrc}' />`;
              }
              return `<img style="text-align: center; height: 250px; width: 40%;background-size:contain;background-position:center;background-repeat:no-repeat;margin: 1% 30% 1% 30%;box-shadow: 5px 5px 5px #888888;" src="${imgSrc}"/>`;
            }
          );
          this.articles[i].content = newContent;
          if (this.languageSelect === "en") {
            if (this.articles[i].secondaryName === "中文商机") {
              this.articles[i].secondaryName = "Chinese Business";
            }
            if (this.articles[i].secondaryName === "英文商机") {
              this.articles[i].secondaryName = "English Business";
            }
          }
          this.tableData.push({
            titleEn: "《" + this.articles[i].titleEn + "》",
            name: this.articles[i].secondaryName,
            date: this.articles[i].createTime,
            title: "《" + this.articles[i].leighttitle + "》",
            fanyititle: this.articles[i].title,
            content: this.articles[i].fanyi,
            id: this.articles[i].id,
            primaryTypeid: this.articles[i].primaryTypeid,
            viewNum: this.articles[i].viewNum,
          });
        }
      }
    },
    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, ""); // 使用正则表达式去除HTML标记
    },
  },
};
</script>


<style scoped lang="scss">
.totlePage {
  width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1300px) {
  .totlePage {
    width: 1160px;
    margin: 0 auto;
  }
}
.scanImg {
  display: inline-block;
  background-image: url("../../assets/scan.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}
.scan {
  justify-content: center;
  background-size: contain;
  width: 90px;
  height: 20px;
  font-size: 14px;
  align-items: center;
  margin-left: 0.5%;
  display: flex;
  line-height: 20px; /* 与容器高度相同的值，使文本垂直居中 */
}
.scan:hover {
  color: var(--main-color);
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
::v-deep .el-table .el-table__cell {
  padding: 12px;
  // text-align: center !important;
  font-weight: normal;
}
// ::v-deep .el-table .el-table__cell:hover {
//   color: var(--main-color);
// }
::v-deep .el-table thead {
  color: #333333;
  text-align: center !important;
}
.custom-header {
  color: #333333;
  font-size: 16px;

  text-align: center; /* 调整文本对齐方式 */
}
::v-deep.el-table th.el-table__cell {
  background-color: rgb(254, 245, 245);
}
.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin: 20px 0;
}

.buttonStyle1 {
  font-size: 16px;
  color: #333333;
  width: 180px;
  height: 45px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle2 {
  font-weight: bold;
  font-size: 18px;
  color: var(--main-color);
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}
.buttonStyle3 {
  font-size: 14px;
  color: #333333;
  width: 230px;
  height: 45px;
  text-align: center;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle4 {
  font-weight: bold;
  font-size: 14px;
  color: var(--main-color);
  width: 230px;
  text-align: center;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}
.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

li {
  list-style-type: none;
}

.transborder {
  border-radius: 5px;
  border-color: #2c3e50;
  box-shadow: 1px 1px 1px 1px #2c3e50;
  width: 55%;
  margin-right: 1%;
  min-height: 85vh;
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}
.news {
  margin-top: 1%;
  display: flex;
  margin-left: 3.5%;
  width: 93%;
}

.secondery {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 230px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;

  //border: black 1px solid;
}

.list {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin-left: 2%;
  width: 100%;
  //border: black 1px solid;
}
.tableStyle {
  width: 90%;
  margin-left: 5%;
  text-align: left;
  margin-top: 5px;
  cursor: pointer; /* 鼠标变成手型 */
}
::v-deep .el-table .row-hover {
  background-color: #ef0e0e !important;
  cursor: pointer; /* 鼠标样式 */
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.subscribe {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}

.artical {
  width: 90%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 150px;
  margin-left: 5%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical:hover {
  background-color: rgba(240, 76, 76, 0.05);
  //transform: translateY(-5px);
}

.detailContent {
  margin-left: 7.5%;
  width: 85%;
  min-height: 94vh;
  height: auto;
}
::v-deep .el-tabs__item {
  font-size: 18px;
  font-weight: 600;
}
::v-deep .el-tabs__item.is-active {
  color: var(--main-color);
}
.news1 {
  width: 100%;
  overflow-x: hidden;

  .noData,
  .notLogin {
    text-align: center;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .noData img,
  .notLogin img {
    width: 100%;
    margin: 0 auto;
  }
  .custom-header {
    color: #333333;
    font-size: 16px;
    text-align: center; /* 调整文本对齐方式 */
  }
  ::v-deep .el-table .el-table__cell {
    padding: 2px;
    font-size: 14px;
    font-weight: normal;
  }

  ::v-deep .el-table .el-table__cell:hover {
    color: var(--main-color);
  }
  ::v-deep .el-table thead {
    color: #333333;
    text-align: center !important;
  }
  ::v-deep .el-tabs__item {
    font-size: 14px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: var(--main-color);
  }
  .pagination-container {
    position: sticky;
    margin-top: auto;
    bottom: 0;
    font-size: 14px;
    text-align: center;
    background-color: #fff;
  }

  .el-pagination {
    width: 90%;
  }

  .secondery {
    width: 100%;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: space-between; /* 水平分布，两端对齐 */
    align-items: center; /* 垂直居中 */
    font-weight: bold;
    font-size: 14px;
    color: black;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    text-align: center;
    //border: black 1px solid;
  }

  .secondery li {
    width: 100%; /* 填充父元素宽度 */
    /* 其他样式属性... */
  }
  .list {
    margin: 5px 0 60px 0;
    height: auto; /* 高度自动增加以适应内容 */
    padding-top: 5px;
    background-color: white;
    width: 100%;
    min-height: 70vh;
    //border: black 1px solid;
  }
  .logo {
    background-image: url("../../assets/artical.png");
    background-size: 18px 22px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
  }
  .logo:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }
  .limitTitLe {
    margin-left: 25px;
    font-size: 16px;
    color: #333333;
    position: absolute;
    width: 45%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .limitTitLe:hover {
    color: var(--main-color);
  }
  .limitTitLe1 {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
    font-size: 16px;
    width: 45%;
    color: #333333;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .limitTitLe1:hover {
    color: var(--main-color);
  }
  .artical {
    width: 96%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 80px;
    margin-left: 2%;

    //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
  }
  .artical1 {
    width: 96%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 80px;
    margin-left: 2%;
    //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
  }
  .buttonStyle1 {
    font-size: 14px;
    color: #333333;
    width: 100%;
    margin: 5px;
    height: 25px;
    padding: 2px;
    border: none;
    box-shadow: none;
    text-align: center;
    border-radius: 0;
    border-right: #333333 2px solid;
  }

  .buttonStyle2 {
    font-size: 16px;
    color: var(--main-color);
    width: 100%;
    height: 25px;
    padding: 2px;
    text-align: center;
    margin: 5px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }
  .buttonStyle3 {
    font-size: 8px;
    color: #333333;
    width: 58px;
    margin: 2px;
    height: 25px;
    padding: 2px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }

  .buttonStyle4 {
    font-size: 10px;
    color: var(--main-color);
    width: 58px;
    margin: 2px;
    height: 25px;
    padding: 2px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }
  .subscribe {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    width: 5px;
    margin: -5px 0 0 38px;
    background-image: url("../../assets/subscribe.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }

  .subscribeDeny {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    margin: -5px 0 0 38px;
    background-image: url("../../assets/subscribeDeny.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }
  .limit {
    text-align: justify;
    color: #666666;
    line-height: 1.5;
    font-size: 14px;
    margin: 5px 5% 0 1%;
    width: 94%; /* 调整为你需要的宽度 */
    height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .limit:hover {
    color: var(--main-color);
  }
  .tag {
    float: right;
    display: flex;
    margin-right: 1%;
    color: gray;
    line-height: 8px;
    font-size: 14px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
  .scanImg {
    display: inline-block;
    background-image: url("../../assets/scan.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }
  .scan:hover {
    color: var(--main-color);

    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  .scan {
    justify-content: center;
    background-size: contain;
    width: 100px;
    height: 15px;
    font-size: 14px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }
  .agreeImg {
    display: inline-block;
    background-image: url("../../assets/agree.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .agree:hover {
    color: var(--main-color);
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  .agreeImgDeny {
    display: inline-block;
    background-image: url("../../assets/agreeReally.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }
  .agree {
    background-size: contain;
    width: 50px;
    height: 10px;
    font-size: 8px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }
  .limitImg {
    margin: 1.5% 2% 0 1%;
    width: 180%; /* 调整为你需要的宽度 */
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 2px rgba(102, 102, 102, 0.8);
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .limitImg:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }

  .limitContent {
    line-height: 1.5;
    font-size: 8px;
    margin: 5px 5% 0 1%;
    width: 94%; /* 调整为你需要的宽度 */
    height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #666666;
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-align: justify;

    p {
      text-align: left;
    }
  }
  .limitContent:hover {
    color: var(--main-color);
  }
  .limitContent1 {
    margin: 1.5% 3% 0 0;
    width: 850px; /* 调整为你需要的宽度 */
    height: 60px;
    padding-right: 60px;
    font-size: 14px;
    color: #666666;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    line-height: 2;

    p {
      text-align: left;
    }
  }
  .limitContent1:hover {
    color: var(--main-color);
  }
}

// me

.brandList {
  overflow: hidden;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 18px;
  padding: 14px 0;
  display: flex;
  ul {
    display: flex;
    li {
      margin-left: 50px;
    }
  }
}

.switch-box {
  overflow: hidden;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 18px;
  padding: 14px 0;
  display: flex;
  button {
    background-color: transparent;
    border: none;
    // background-color: #ccc;
    min-width: 100px;
    padding: 10px 20px;
    margin-right: 5px;
    cursor: pointer;
  }
  button.active {
    background: #ffe6d7;
    color: #ff8803;
  }
}

.sort-box {
  display: flex;
  margin: 0 18px;
  padding: 14px 0;
  align-items: center;
  justify-content: space-between;
}
.sort-btn {
  display: flex;
}
.sort {
  font-size: 14px;
  padding: 10px 10px 10px 0;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    right: -10px;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #bbb transparent;
    cursor: pointer;
  }
  &::before {
    transform: translateY(-125%);
    border-width: 0 4px 5px;
  }
  &::after {
    transform: translateY(25%);
    border-width: 5px 4px 0;
  }
  &.asc::before {
    border-bottom-color: var(--main-color);
  }
  &.desc::after {
    border-top-color: var(--main-color);
  }
}
.table-data-sum {
  font-size: 12px;
  color: #2c3e50;
  span {
    color: #ff8803;
  }
}

.sortBg {
  position: absolute;
  top: 14px;
  right: 0px;
}

.sortBg1 {
  position: absolute;
  top: 20px;
  right: 0px;
}

::v-deep .el-table th.el-table__cell {
  background-color: #f7f8fa !important;
  font-size: 16px;
  color: #333 !important;
}

::v-deep .el-table th.el-table__cell > .cell {
  color: #333 !important;
}

.price {
  background: #fef1e3;
  font-size: 12px;
  font-weight: 400;
  color: #ff8803;
  line-height: 20px;
  // min-width: 30px;
  height: 20px;
  margin-right: 12px;
  white-space: nowrap;
  // display: inline-flex;
  text-align: center;
  vertical-align: middle;
}
.el-table ::v-deep .cell{
  color: #999;
}

// ::v-deep .el-table_1_column_2 > .cell {
//   color: #999999;
// }

// ::v-deep .el-table_1_column_3 > .cell {
//   color: #999999;
// }

// ::v-deep .el-table_1_column_4 > .cell {
//   color: #0260ff;
// }

.table-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  .twoLine{
    color: #333;
    &:hover{
      color: var(--main-color);
    }
    flex: 1;
  }
}

// 移动端
.commercialImage {
  width: 100%;
  height: 104px;
  vertical-align: top;
}

.switch-box1 {
  overflow: hidden;
  background: #f5f9fe;
  margin: 4px 18px;
  display: flex;
  font-size: 12px;
  &:first-child{
    margin-top: 18px;
  }
  span {
    display: inline-block;
    font-size: 12px;
    background-color: transparent;
    border: none;
    // background-color: #ccc;
    min-width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    // padding: 10px 20px;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    &.active {
      background: var(--main-color);
      color: #fff;
    }
  }
}

.sort-box1 {
  display: flex;
  margin: 0 18px;
  // padding: 14px 0;
}

.sortT1 {
  display: inline-block;
  height: 15px;
  border-left: 0.04rem solid #1a79ff;
  vertical-align: middle;
  margin-top: 18px;
}

.sortT2 {
  display: inline-block;
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  color: #17181a;
  vertical-align: middle;
}

.sortBox {
  width: 94%;
  display: block;
  height: 124px;
  background: #fff url('../../assets/list-bj.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 4px;
  padding: 10px;
  margin: 0 10px 10px 10px;
}

.sortBoxHeader {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  span {
    display: block;
    margin-right: 8px;
    font-size: 10px;
    color: #fff;
    padding: 2px 6px;
    border-radius: 14px;
    background-color: var(--main-bg-color);
  }
  p{
    flex: 1;
  }
}

.sortBoxT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  p {
    font-size: 12px;
    // display: flex;
    width: 49%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #f7f8f9;
    border-radius: 0.1rem;
    text-align: left;
    padding-left: 10px;
  }
}

.sortBoxT1 {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  p {
    font-size: 12px;
    // display: flex;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #f7f8f9;
    border-radius: 0.1rem;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  span {
    border: 1px solid;
    padding: 5px 20px;
  }
  button {
  margin: 0 10px;
  padding: 5px 20px;
  background: #fff;
}
}

.custom-opportunity-page{
  background-color: #f2f3f5;
  .totlePage{
    padding-top: 20px;
  }
  .switch-box{
    margin: 0;
    span{
      display: block;
      min-width: 100px;
      text-align: center;
      padding: 10px 20px;
      margin-right: 5px;
      box-sizing: border-box;
      cursor: pointer;
      &.active {
        background: #ffe6d7;
        color: #ff8803;
      }
    }
  }
  .custom-filter-box{
    background-color: #fff;
    border-radius: 4px;
    font-size: 14px;
  }
  .sort-box{
    border-radius: 4px;
    margin: 20px 0 8px;
    padding: 0 20px 0 0;
    background-color: #fff;
    font-size: 14px;
  }
  .custom-table-box{
    padding: 20px;
    background-color: #fff;
  }
}





</style>

