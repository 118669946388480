<template>
  <div>
    <Header @keywords="handleInfoFromHeader" @handle-nav="navHandle"></Header>
    <div class="wrapper">
      <div v-if="isMobile === false" class="totalPage">
        <!-- <div class="news">
          <div class="secondery">
            <li
              v-for="(seconderyClassfys, index) in seconderyClassfy"
              :key="index"
            >
              <div style="display: flex" v-if="languageSelect === 'zh'">
                <el-button
                  :class="
                    seconderyClassfys.buttonStyle
                      ? 'buttonStyle2'
                      : 'buttonStyle1'
                  "
                  @click="sendSecondery(seconderyClassfys)"
                >
                  <i class="el-icon-location"></i>
                  <span>{{ seconderyClassfys.title }}</span>
                </el-button>
                <el-tooltip effect="dark" content="订阅" placement="top">
                  <el-button
                      v-if="seconderyClassfys.isSubscribe === 0"
                      class="subscribe"
                      @click="subscribeThis(seconderyClassfys)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="取消订阅" placement="top">
                  <el-button
                      v-if="seconderyClassfys.isSubscribe === 1"
                      class="subscribeDeny"
                      @click="subscribeThis(seconderyClassfys)"
                  ></el-button>
                </el-tooltip>
              </div>
              <div style="display: flex" v-if="languageSelect === 'en'">
                <el-button
                  :class="
                    seconderyClassfys.buttonStyle
                      ? 'buttonStyle4'
                      : 'buttonStyle3'
                  "
                  @click="sendSecondery(seconderyClassfys)"
                >
                  <i class="el-icon-location"></i>
                  <span>{{ seconderyClassfys.titleEn }}</span>
                </el-button>
                <el-tooltip effect="dark" content="subscribe" placement="top">
                  <el-button
                      v-if="seconderyClassfys.isSubscribe === 0"
                      class="subscribe"
                      @click="subscribeThis(seconderyClassfys)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="unsubscribe" placement="top">
                  <el-button
                      v-if="seconderyClassfys.isSubscribe === 1"
                      class="subscribeDeny"
                      @click="subscribeThis(seconderyClassfys)"
                  ></el-button>
                </el-tooltip>
              </div>
            </li>
          </div>
          <div class="list" ref="list">
            <div>
              <div style="width: 100%; margin-left: 1%">
                <div
                  v-if="isLoading === true"
                  style="width: 100%; min-height: 70vh"
                >
                  <template>
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      暂未查询到任何讯息，请换个条件试试
                    </div>
                  </template>
                </div>
                <div style="min-height: 70vh; cursor: pointer">
                  <li v-for="(article, index) in articles" :key="index">
                    <div
                      :class="article.imgChange === 0 ? 'artical1' : 'artical'"
                      @mouseover="hoverIn"
                      @mouseleave="hoverOut"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin-top: 2%;
                        "
                      >
                        
                        <div
                          class="logo"
                          style="width: 20px; height: 20px"
                        ></div>
                        
                        <div
                          v-if="languageSelect === 'zh'"
                          v-html="article.leighttitle"
                          class="limitTitLe"
                          @click="detailArtical(article)"
                        ></div>
                        <div
                          v-if="languageSelect === 'en'"
                          v-html="article.titleEn"
                          class="limitTitLe1"
                          @click="detailArtical(article)"
                        ></div>
                        
                        <div
                          style="
                            color: gray;
                            margin-left: auto;
                            text-align: right;
                            margin-right: 5%;
                          "
                        >
                          {{ article.createTime }}
                        </div>
                      </div>
                      
                      <div v-if="article.imgChange === 0">
                        <div
                          v-if="languageSelect === 'zh'"
                          v-html="article.truncatedContent"
                          class="limit"
                          @click="detailArtical(article)"
                        ></div>
                        <div
                          v-if="languageSelect === 'en'"
                          v-html="article.contentEn"
                          class="limit"
                          @click="detailArtical(article)"
                        ></div>
                        <div class="tag">
                          <div class="scan">
                            <div class="scanImg"></div>
                            &nbsp; &nbsp;{{ article.scan }} &nbsp; &nbsp;
                          </div>
                          <div class="agree" @click="agreeChange(article)">
                            <div
                              v-if="article.iskouds === 0"
                              class="agreeImg"
                            ></div>
                            <div
                              v-if="article.iskouds === 1"
                              class="agreeImgDeny"
                            ></div>
                            <span style="margin-top: 2%">
                              &nbsp; &nbsp;{{ article.agree }} &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="article.imgChange === 1"
                        style="display: flex; height: 75px"
                      >
                        <div v-html="article.contentImg" class="limitImg"></div>
                        <div>
                          <div
                            v-if="languageSelect === 'zh'"
                            v-html="article.truncatedContent"
                            class="limitContent"
                            @click="detailArtical(article)"
                          ></div>
                          <div
                            v-if="languageSelect === 'en'"
                            v-html="article.contentEn"
                            class="limitContent1"
                            @click="detailArtical(article)"
                          ></div>
                          <div class="tag">
                            <div class="scan">
                              <div class="scanImg"></div>
                              &nbsp; &nbsp;{{ article.scan }} &nbsp; &nbsp;
                            </div>
                            <div class="agree" @click="agreeChange(article)">
                              <div
                                v-if="article.iskouds === 0"
                                class="agreeImg"
                              ></div>
                              <div
                                v-if="article.iskouds === 1"
                                class="agreeImgDeny"
                              ></div>
                              <span style="margin-top: 2%">
                                &nbsp; &nbsp;{{ article.agree }} &nbsp; &nbsp;
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>

                
                <div v-if="articles.length !== 0" class="pagination-container">
                  <el-pagination
                    v-model="page"
                    :current-page="page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[5, 8, 10, 15]"
                    :page-size="size"
                    layout="sizes, prev, pager, next, jumper"
                    background
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
          <AiPop @change="showDialog"></AiPop>
        </div> -->
        <div
          class="centerWidth"
          style="display: flex;justify-content: space-between;">
          <div class="content-box">
            <el-tabs v-model="tabActive" @tab-click="handleClick">
              <el-tab-pane :label="$t('common.all')" name="0"></el-tab-pane>
              <el-tab-pane v-for="item in cates" :key="item.id" :label="item[trans('title,titleEn')]"></el-tab-pane>
            </el-tabs>

            <div v-if="dataList.length">
              <div class="new-list-box" v-for="item in dataList" :key="item.id">
                  <img @click="toDetail(item.id)" :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" style="width: 210px; height: 140px" />
                  <div style="flex: 1; padding-left: 20px">
                    <p class="tecP1" @click="toDetail(item.id)">{{item[trans('title,titleEn')]}}</p>
                    <p class="tecP2 twoLine" v-html="item[trans('content,contentEn')]"></p>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        line-height: 20px;
                        margin-top: 28px;
                      "
                    >
                      <div>
                        <span
                          class="label-box"
                          v-for="(label, labelIndex) in (item[trans('label,labelEn')] ? item[trans('label,labelEn')].split(',') : [])"
                          :key="labelIndex"
                          >{{ label }}</span
                        >
                      </div>
                      <p
                        style="
                          font-size: 12px;
                          line-height: 20px;
                          color: #a1a8b3;
                        "
                      >
                        {{item.createTime}}
                      </p>
                    </div>
                  </div>
              </div>
              <div class="pagination-container">
                <el-pagination
                  hide-on-single-page
                  :pager-count="5"
                  layout="prev, pager, next"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :total="total"
                  background>
                </el-pagination>
              </div>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>

          </div>
          <Right :modules="rightModules"></Right>
          
        </div>
        <AiPop @change="showDialog"></AiPop>
      </div>
      <div v-if="isMobile === true">
        <div class="news1">
          <div class="m-list-nav-wrap">
            <div class="m-list-nav">
              <a :class="{'m-l-nav-active': mNavActive == 0 }" href="javascript:void(0);" @click="mSelectNav(0)">{{$t('common.all') }}</a>
              <a :class="{'m-l-nav-active': mNavActive == index + 1 }" href="javascript:void(0);" @click="mSelectNav(index + 1)" v-for="(item, index) in cates" :key="item.id">{{ item[trans('title,titleEn')] }}</a>
            </div>
          </div>
          <template v-if="dataList.length">
            <div class="m-info-wrap">
              <div class="m-info-box" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                <div class="m-info-txt">
                  <p class="m-info-title twoLine">{{item[trans('title,titleEn')]}}</p>
                  <div class="m-info-time">
                    <span class="">{{item.createTime}}</span>
                  </div>
                </div>
                <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" class="m-info-img" />
              </div>
            </div>

            <Pagination :size="size" @current-change="handleCurrentChange" :total="total"></Pagination>
          </template>
          <!-- <TecTab></TecTab> -->
          <!-- <div class="secondery">
            <li
              v-for="(seconderyClassfys, index) in seconderyClassfy"
              :key="index"
            >
              <div
                style="display: flex; align-items: center"
                v-if="languageSelect === 'zh'"
              >
                <el-button
                  :class="
                    seconderyClassfys.buttonStyle
                      ? 'buttonStyle2'
                      : 'buttonStyle1'
                  "
                  @click="sendSecondery(seconderyClassfys)"
                >
                  <span>{{ seconderyClassfys.title }}</span>
                </el-button>
                <el-button
                  v-if="seconderyClassfys.isSubscribe === 0"
                  class="subscribe"
                  @click="subscribeThis(seconderyClassfys)"
                ></el-button>
                <el-button
                  v-if="seconderyClassfys.isSubscribe === 1"
                  class="subscribeDeny"
                  @click="subscribeThis(seconderyClassfys)"
                ></el-button>
              </div>
              <div
                style="display: flex; align-items: center"
                v-if="languageSelect === 'en'"
              >
                <el-button
                  :class="
                    seconderyClassfys.buttonStyle
                      ? 'buttonStyle4'
                      : 'buttonStyle3'
                  "
                  @click="sendSecondery(seconderyClassfys)"
                >
                  <span>{{ seconderyClassfys.titleEn }}</span>
                </el-button>
                <el-button
                  v-if="seconderyClassfys.isSubscribe === 0"
                  class="subscribe"
                  @click="subscribeThis(seconderyClassfys)"
                ></el-button>
                <el-button
                  v-if="seconderyClassfys.isSubscribe === 1"
                  class="subscribeDeny"
                  @click="subscribeThis(seconderyClassfys)"
                ></el-button>
              </div>
            </li>
          </div>
          <div class="list" ref="list">
            <div>
              <div style="width: 100%; margin-left: 1%">
                <div
                  v-if="isLoading === true"
                  style="width: 100%; min-height: 70vh"
                >
                  <template>
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      暂未查询到任何讯息，请换个条件试试
                    </div>
                  </template>
                </div>
                <div style="min-height: 70vh; cursor: pointer">
                  <li v-for="(article, index) in articles" :key="index">
                    <div
                      :class="article.imgChange === 0 ? 'artical1' : 'artical'"
                      @mouseover="hoverIn"
                      @mouseleave="hoverOut"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin-top: 2%;
                        "
                      >
                      
                        <div
                          class="logo"
                          style="width: 20px; height: 20px"
                        ></div>
                        
                        <div
                          v-if="languageSelect === 'zh'"
                          v-html="article.leighttitle"
                          class="limitTitLe"
                          @click="detailArtical(article)"
                        ></div>
                        <div
                          v-if="languageSelect === 'en'"
                          v-html="article.titleEn"
                          class="limitTitLe1"
                          @click="detailArtical(article)"
                        ></div>
                      
                        <div
                          style="
                            color: gray;
                            margin-left: auto;
                            text-align: right;
                            margin-right: 5%;
                            font-size: 14px;
                          "
                        >
                          {{ article.createTime }}
                        </div>
                      </div>
                     
                      <div v-if="article.imgChange === 0">
                        <div
                          v-if="languageSelect === 'zh'"
                          v-html="article.truncatedContent"
                          class="limit"
                          @click="detailArtical(article)"
                        ></div>
                        <div
                          v-if="languageSelect === 'en'"
                          v-html="article.contentEn"
                          class="limit"
                          @click="detailArtical(article)"
                        ></div>
                        <div class="tag">
                          <div class="scan">
                            <div class="scanImg"></div>
                            &nbsp; &nbsp;{{ article.scan }} &nbsp; &nbsp;
                          </div>
                          <div class="agree" @click="agreeChange(article)">
                            <div
                              v-if="article.iskouds === 0"
                              class="agreeImg"
                            ></div>
                            <div
                              v-if="article.iskouds === 1"
                              class="agreeImgDeny"
                            ></div>
                            <span style="margin-top: 2%">
                              &nbsp; &nbsp;{{ article.agree }} &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="article.imgChange === 1"
                        style="display: flex; height: 75px"
                      >
                        <div v-html="article.contentImg" class="limitImg"></div>
                        <div>
                          <div
                            v-if="languageSelect === 'zh'"
                            v-html="article.truncatedContent"
                            class="limitContent"
                            @click="detailArtical(article)"
                          ></div>
                          <div
                            v-if="languageSelect === 'en'"
                            v-html="article.contentEn"
                            class="limitContent1"
                            @click="detailArtical(article)"
                          ></div>
                          <div class="tag1">
                            <div class="scan">
                              <div class="scanImg"></div>
                              &nbsp; &nbsp;{{ article.scan }} &nbsp; &nbsp;
                            </div>
                            <div class="agree" @click="agreeChange(article)">
                              <div
                                v-if="article.iskouds === 0"
                                class="agreeImg"
                              ></div>
                              <div
                                v-if="article.iskouds === 1"
                                class="agreeImgDeny"
                              ></div>
                              <span style="margin-top: 2%">
                                &nbsp; &nbsp;{{ article.agree }} &nbsp; &nbsp;
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div v-if="articles.length !== 0" class="pagination-container">
                  <el-pagination
                    v-model="page"
                    :current-page="page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[5, 8, 10, 15]"
                    :page-size="size"
                    layout="total, prev, next, jumper"
                    background
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div> -->
          <ScrollTop></ScrollTop>
          <AiPop @change="showDialog"></AiPop>
        </div>
      </div>
    </div>
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <CancleTip
      :dialogVisible="cancleVisible"
      @hide="hide"
      @confirmDialog="confirmDialog"
    ></CancleTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true"></Bottom>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import LoginTip from "@/components/LoginTip.vue";
import CancleTip from "@/components/CancleTip.vue";
import InfoTip from "@/components/InfoTip.vue";
import Bottom from "@/components/mobile/Bottom.vue";
// import TecTab from "@/components/mobile/TecTab.vue";
import { shareUrl } from "@/utils/vxshare.js";
import Right from "@/components/Right.vue";
import mixins from "@/utils/mixins.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue"
import Pagination from "@/components/mobile/Pagination.vue";

import {
  getNews,
  getNewsEn,
  getNewsCates
} from "@/api/index";

export default {
  name: "NewsList",
  mixins: [mixins],
  components: {
    ScrollTop,
    Bottom,
    InfoTip,
    CancleTip,
    LoginTip,
    AiPop,
    Header,
    Footer,
    Right,
    Pagination
    // TecTab,
  },
  props: {
    // eslint-disable-next-line no-undef
    primaryTypeid: String,
  },
  data() {
    return {
      activeName: "first",
      isMobile: false,
      languageSelect: "",
      cancleVisible: false,
      cancleId: null,
      dialogVisible: false,
      translateThis: 1,
      subscribe: 0,
      maxTextLength: 125, // 设置最大字数
      searchKeywords: "",
      seconderyClassfy: [],
      isLoading: "",
      articles: [],
      hovered: false,
      content: [],
      collect: 0,
      translatedetaiContent: "",
      detailTitle: "",
      detailTime: "",
      detailContent: "",
      translatedetailTitle: "",
      zhStr: "",
      enStr: "",
      salt: parseInt(Math.random() * 1000000000), //随机数
      appid: "20231016001849033",
      userkey: "ZgPv1FfEEWXPsNH0HzWl",
      accessToken: "",
      newsId: "",
      fanyi: "",
      q: "",
      token: "",
      userId: "",
      buttonStyle: false, // 控制按钮样式的变量
      activeIndex: null, // 记录被点击的按钮索引
      isView: "",
      isWhole: "",
      dialogVisibleInfo: false,
      user: {
        userId: "",
        name: "",
      },


      cates: [],
      tabActive: 0,
      size: 15,
      page: 1,
      total: 1,
      dataList: [],
      secondaryTypeid: "",
      rightModules: [
        {
          type: 'technology',
          path: 'news',
          title: this.$t('right.latestNews'),
          dataNum: 5,
          primaryTypeId: 4
        },
        {
          type: 'report',
          path: 'report',
          title: this.$t('right.latestReport'),
          dataNum: 5,
          primaryTypeId: 2
        }
      ],
      mNavActive: 0
    };
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    const currentPage = localStorage.getItem("setPage");
    if (currentPage) {
      try {
        const parsedPage = JSON.parse(currentPage);
        if (parsedPage.primaryTypeid === this.primaryTypeid) {
          if (parseInt(parsedPage.setPage)) {
            this.page = parseInt(parsedPage.setPage);
          } else {
            this.page = 1;
          }
          localStorage.setItem("setPage", "");
          localStorage.setItem("Title", "");
        } else {
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
          this.page = 1;
        }
      } catch (error) {
        localStorage.setItem("Title", "");
        localStorage.setItem("setPage", ""); // 清空 localStorage
        // 处理 JSON 解析错误
        this.page = 1; // 或者其他处理逻辑
      }
    } else {
      this.page = 1;
    }


    if(this.primaryTypeid == 4){
      this.rightModules[0].primaryTypeId = 1
      this.rightModules[0].title = this.$t('index.technology')
      this.rightModules[0].path = 'technology'
    }

    await this.getNewsCates();
    if(!this.$route.params.cateId) {
      await this.getNews();
    }else{
      this.navHandle(this.$route.params)
    }

    //   是否登录才可查看
    // this.loginView();
    //   获取新闻二级分类列表
    // this.classfy();
    //   获取新闻
    // this.getartical();
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    async getNewsCates(){
      let res = await getNewsCates({
        level: 2,
        parentId: this.primaryTypeid
      })

      this.cates = res.data
    },
    async getNews(){
      let fn = this.$i18n.locale == 'en' ? getNewsEn : getNews;
      let res = await fn({
        primaryTypeid: this.primaryTypeid,
        secondaryTypeid: this.secondaryTypeid,
        pageNum: this.page,
        pageSize: this.size,
        draftStatus: 0,
        isSort: 2,
        articleFlag: 1,
      })
      this.dataList = res.rows.map(item => {
        item.content = this.setText(item.content || '').substr(0, 150)
        item.contentEn = this.setText(item.contentEn || '').substr(0, 150)

        return item
      })
      this.total = res.total
    },
    handleCurrentChange(val) {
      this.page = val;

      this.getNews();
      this.handleScroll();
    },
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0);
    },
    handleClick() {
      this.secondaryTypeid = this.tabActive == 0 ? '' : this.cates[this.tabActive - 1].categoryId;
      this.page = 1;

      this.getNews();
      this.handleScroll();
    },
    toDetail(id){
      let map = {
        1: 'technology',
        4: 'news',
        2: 'report',
        3: 'opportunity'
      }
      this.$router.push('/'+ map[this.primaryTypeid] +'/' + id);
    },
    mSelectNav(index){
      this.mNavActive = index;
      this.secondaryTypeid = this.mNavActive == 0 ? '' : this.cates[this.mNavActive - 1].categoryId;
      this.page = 1;

      this.getNews();
      this.handleScroll();
    },
    navHandle(data){
      let index = this.cates.findIndex(item => {
        return item.categoryId == data.cateId
      })
      this.mSelectNav(index + 1)
    },




    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    // 点赞
    agreeChange(artical) {
      if (this.user.userId) {
        if (artical.iskouds === 0) {
          this.$axios
            .post(
              "/api/kudos/addOrDelete",
              {
                newsId: JSON.stringify(artical.id),
                userId: this.user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then(() => {
              artical.iskouds = 1;
              artical.agree++;
            });
        } else {
          this.$axios
            .post(
              "/api/kudos/addOrDelete",
              {
                newsId: JSON.stringify(artical.id),
                userId: this.user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              console.log(res);
              artical.iskouds = 0;
              artical.agree--;
            });
          // this.agreenewsId=artical.id
          // this.cancleAgree=true
        }
      } else {
        this.dialogVisible = true;
      }
    },

    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.cancleVisible = false;
    },
    confirmDialog() {
      this.$axios
        .get("/api/subscribe/delete/" + parseInt(this.cancleId.subscribeId), {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          // this.$set(this.cancleId, "isSubscribe", 0);
          this.cancleVisible = false;
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消成功",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancel successfully",
              showClose: false,
            });
          }

          if (res.data.msg === "操作成功") {
            this.$set(this.cancleId, "isSubscribe", 0);
          }
          this.classfy();
        })
        .catch((error) => {
          console.log(error);
          if (this.languageSelect === "zh") {
            this.$notify({
              title: "提示",
              message: "取消失败，请稍后再试",
            });
          } else {
            this.$notify.warning({
              title: "Tips",
              message: "Cancellation failed, please try again later",
              showClose: false,
            });
          }
        });
    },
    // change改变
    handleChildValue(value) {
      this.change = value;
    },
    // 订阅
    subscribeThis(sub) {
      if (this.user.userId) {
        if (sub.isSubscribe === 0) {
          this.$axios
            .post(
              "/api/subscribe/add",
              {
                primaryType: this.primaryTypeid,
                secondaryType: sub.categoryId,
                type: "1",
                userId: this.user.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "订阅成功",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Subscribe successfully",
                  showClose: false,
                });
              }

              if (res.data.msg === "操作成功") {
                this.$set(sub, "isSubscribe", 1);
              }
              this.classfy();
              // console.log(res)
            })
            .catch((error) => {
              console.log(error);
              if (this.languageSelect === "zh") {
                this.$notify({
                  title: "提示",
                  message: "取消失败，请稍后再试",
                });
              } else {
                this.$notify.warning({
                  title: "Tips",
                  message: "Cancellation failed, please try again later",
                  showClose: false,
                });
              }
            });
        } else {
          this.cancleId = sub;
          this.cancleVisible = true;
        }

        this.$forceUpdate();
      } else {
        this.dialogVisible = true;
      }
      //
      // console.log(this.seconderyClassfy)
    },
    // 关键词
    handleInfoFromHeader(info) {
      this.searchKeywords = info.seachInput;
      // this.getartical();
      // console.log("关键词"+this.searchKeywords)
    },
    //收藏
    collectChange() {
      if (this.collect === 1) {
        this.collect = 0;
      } else if (this.collect === 0) {
        this.collect = 1;
      }
    },
    // 二级分类
    sendSecondery(index) {
      // 记录被点击的按钮索引
      // console.log("cdcd")
      for (var i = 0; i < this.seconderyClassfy.length; i++) {
        this.seconderyClassfy[i].buttonStyle = false;
      }
      // console.log(index)
      index.buttonStyle = true;
      // console.log('Clicked item at index:', index)
      this.searchKeywords = "";
      this.secondaryTypeid = index.categoryId;
      this.page = 1;
      this.size = 8;
      // this.getartical();
    },
    isActive(index) {
      // 检查按钮是否被点击过，返回相应的布尔值来动态绑定类名
      return this.activeIndex === index;
    },
    // 获取二级分类
    classfy() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 2,
            parentId: this.primaryTypeid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          this.seconderyClassfy = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组
          for (var i = 0; i < this.seconderyClassfy.length; i++) {
            this.seconderyClassfy[i].buttonStyle = false;
          }
          if (this.$route.query.TypeId) {
            for (var j = 0; j < this.seconderyClassfy.length; j++) {
              console.log(typeof this.$route.query.TypeId);
              if (
                this.seconderyClassfy[j].categoryId ===
                parseInt(this.$route.query.TypeId)
              ) {
                var index = this.seconderyClassfy[j];
                this.sendSecondery(index);
                break;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hoverIn() {
      this.hovered = true;
    },
    hoverOut() {
      this.hovered = false;
    },

    //   新闻
    detailArtical(artical) {
      this.detailTitle = artical.title;
      this.detailContent = artical.content;
      this.q = artical.fanyi;
      this.detailTime = artical.createTime;
      this.newsId = artical.id;
      // 不需要登录
      if (this.isView === "1") {
        // this.$router.push({
        //   path: "/articalDetail",
        //   query: {
        //     currentPage: this.page,
        //     Typeid: artical.primaryTypeid,
        //     newId: artical.id,
        //   },
        // });
        this.$router.push({
          name: "TechnologyDetail",
          params: {
            page: this.page,
            typeId: artical.primaryTypeid,
            id: artical.id,
          },
        });
      }
      // 需要登录
      else {
        // 已经登
        if (this.user.userId) {
          // 无需完善
          if (this.isWhole === "1") {
            this.$router.push({
              path: "/articalDetail",
              query: {
                currentPage: this.page,
                Typeid: artical.primaryTypeid,
                newId: artical.id,
              },
            });
          }
          // 需要完善
          else {
            if (this.$store.state.typeInfo == 0) {
              this.dialogVisibleInfo = true;
            } else {
              this.$router.push({
                path: "/articalDetail",
                query: {
                  currentPage: this.page,
                  Typeid: artical.primaryTypeid,
                  newId: artical.id,
                },
              });
            }
          }
        } else {
          this.dialogVisible = true;
        }
      }
    },
    //获取是否登录可查看
    loginView() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
            categoryId: this.primaryTypeid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isView = res.data.data[0].isView;
          this.isWhole = res.data.data[0].isWhole;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //   获取文章
    getartical() {
      // console.log("新闻"+this.searchKeywords)
      this.$axios
        .get("/api/news/list", {
          params: {
            primaryTypeid: this.primaryTypeid,
            secondaryTypeid: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
            draftStatus: 0,
            articleFlag: 1,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          window.scrollTo(0, 0);
          this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
          // 重新生成列表
          console.log(this.articles);
          // this.list();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("/api/news/getNews/list", {
          params: {
            primaryTypeid: this.primaryTypeid,
            secondaryTypeid: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
            draftStatus: 0,
            articleFlag: 1,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.total = res.data.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
      // 动态调整页面高度
      this.adjustPageHeight();
    },
    adjustPageHeight() {
      // 使用setTimeout确保Vue有时间更新页面内容
      // this.$nextTick(() => {
      //   const contentHeight = this.$refs.content.scrollHeight;
      //   const listElement = this.$refs.list;
      //
      //   if (contentHeight > listElement.clientHeight) {
      //     listElement.style.height = contentHeight + 'px';
      //   }
      // });
    },
    async list() {
      if (this.articles.length === 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
        for (var i = 0; i < this.articles.length; i++) {
          this.content[i] = this.articles[i].content;
          const currentDate = new Date(this.articles[i].createTime);
          const year = currentDate.getFullYear();
          const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          const day = ("0" + currentDate.getDate()).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;
          // this.primaryTypeid[i] = this.articles[i].primaryTypeid
          // this.secondaryTypeid[i] = this.articles[i].secondaryTypeid
          var vm = this;
          await vm.$axios
            .post("/api/enes/trans", {
              // params: {
              newsId: vm.articles[i].id,
              // },
              headers: {
                Authorization: vm.token,
              },
            })
            .then((res) => {
              // console.log(res.data.data.enTitle)
              vm.titleEn = res.data.data.enTitle;
              vm.contentEn = res.data.data.enContent;
            })
            .catch((error) => {
              console.log(error);
            });
          this.articles[i].titleEn = this.titleEn;
          this.articles[i].contentEn = this.contentEn;

          this.articles[i].fanyi = this.stripHtmlTags(this.articles[i].content);
          if (this.articles[i].content.length > this.maxTextLength) {
            const truncatedText =
              this.articles[i].fanyi.slice(0, this.maxTextLength) + "…";
            this.articles[i].truncatedContent = truncatedText; // 保存截断后的内容到新字段
          } else {
            this.articles[i].truncatedContent = this.articles[i].content; // 如果没有截断，保存原始内容
          }
          this.articles[i].leighttitle = this.articles[i].title.replace(
            new RegExp(this.searchKeywords, "gi"),
            (match) => `<span style="color: var(--main-color)">${match}</span>`
          );
          this.articles[i].imgChange = 0;
          if (this.articles[i].viewNum === null) {
            this.articles[i].scan = 0;
          } else {
            this.articles[i].scan = this.articles[i].viewNum;
          }
          if (this.articles[i].koudsNum === null) {
            this.articles[i].agree = 0;
          } else {
            this.articles[i].agree = this.articles[i].koudsNum;
          }
          this.articles[i].createTime = formattedDate;

          // 使用简单的字符串替换来处理src属性
          let newContent = this.content[i].replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            (match, p1) => {
              let imgSrc =
                p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
              this.articles[i].imgChange = 1;
              // console.log(this.articles[i].contentImg)
              // 获取第一张图片
              if (this.articles[i].contentImg === undefined) {
                this.articles[
                  i
                ].contentImg = `<img style="text-align: center;box-shadow: 5px 5px 5px #888888;width:100%;height:100%" src='${imgSrc}' />`;
              }
              return `<img style="text-align: center; background-size:contain;background-position:center;background-repeat:no-repeat;margin: 1% 30% 1% 30%;box-shadow: 5px 5px 5px #888888;" src="${imgSrc}"/>`;
            }
          );

          this.articles[i].content = newContent;
          this.content[i] = newContent;
        }
      }
    },
    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, ""); // 使用正则表达式去除HTML标记
    },
    // 分页
    handleSizeChange(val) {
      this.size = val;
      // console.log(`每页 ${val} 条`+this.size)
      // console.log('gengxin')
      // this.getartical();
    }
  },
  watch: {
    // "$store.state.user"(newValue) {
    //   this.user = newValue;
    // },
    // primaryTypeid: function () {
    //   this.page = 1;
    //   this.secondaryTypeid = "";
    //   this.handleCurrentChange(1);
    //   this.classfy();
    //   //   获取新闻
    //   this.getartical();
    // },
  },
};
</script>


<style scoped lang="scss">
.totalPage {
  margin: 20px auto 0;
}
@media (max-width: 1300px) {
  .totalPage {
    width: 1220px;
    margin: 0 auto;
  }
}

.limitTitLe {
  margin-left: 35px;
  font-size: 18px;
  color: #333333;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limitTitLe:hover {
  color: var(--main-color);
}

.limitTitLe1 {
  display: flex;
  justify-content: space-between;
  margin-left: 35px;
  font-size: 18px;
  width: 45%;
  color: #333333;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.limitTitLe1:hover {
  color: var(--main-color);
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  padding: 30px 0 40px;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
}

.buttonStyle1 {
  font-size: 16px;
  color: #333333;
  width: 180px;
  height: 45px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle2 {
  font-weight: bold;
  font-size: 18px;
  color: var(--main-color);
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle3 {
  font-size: 14px;
  color: #333333;
  width: 180px;
  height: 45px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  text-align: left;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle4 {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  color: var(--main-color);
  width: 180px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 4px 2px var(--main-color);
}

.subscribe {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  border: none;
  margin: 5px 0 0 145px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 3.5%;
  width: 93%;
}

.secondery {
  width: 20%;
  height: 100%;
  //border: black 1px solid;
}

.list {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin-left: 2%;
  width: 100%;
  //border: black 1px solid;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.talk_sub {
  font-size: 14px;
  width: 50%;
  margin-top: 15vh;
  height: 30vh;
  border-radius: 10px;
  background-image: url("../../assets/none.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.talk_word {
  display: flex;
  min-height: 40px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 60%;
  margin-left: 17.5%;
  max-height: 40px;
  padding: 0px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  border: rgb(84, 82, 82) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.cascader {
  width: 20%;
  border-color: white !important;
}

.secondery {
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 200px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  text-align: left;
}

.secondery :active {
  color: var(--main-color);
}

.talkcontent {
  min-height: 22px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 40%;
  max-height: 22px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 2%;
  margin-top: 0.5%;
  //border: black solid 1px; background-color: white; /* 半透明白色，alpha值为0.5 */
}

::v-deep .el-cascader .el-input__inner:hover {
  border: none;
}

.logo {
  background-image: url("../../assets/artical.png");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}

.logo:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}

.artical {
  position: relative;
  width: 96%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 130px;
  margin-left: 2%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical1 {
  position: relative;
  width: 96%;
  padding-right: 60px;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 125px;
  margin-left: 2%;
  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.detailContent {
  margin-left: 7.5%;
  width: 85%;
  min-height: 94vh;
  height: auto;
}

.custom-cascader .el-cascader-input input {
  border: none !important;
}

.custom-cascader .el-cascader-menu {
  border: 1px solid #fff !important; /* 设置下拉菜单边框为白色 */
}

.limitContent {
  margin: 1.5% 3% 0 0;
  width: 800px; /* 调整为你需要的宽度 */
  height: 60px;
  font-size: 14px;
  color: #666666;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  line-height: 2;

  p {
    text-align: left;
  }
}

.limitContent:hover {
  color: var(--main-color);
}

.limitContent1 {
  margin: 1.5% 3% 0 0;
  width: 850px; /* 调整为你需要的宽度 */
  height: 60px;
  padding-right: 60px;
  font-size: 14px;
  color: #666666;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  line-height: 2;

  p {
    text-align: left;
  }
}

.limitContent1:hover {
  color: var(--main-color);
}

.limit {
  text-align: left;
  color: #666666;
  line-height: 2;
  font-size: 14px;
  margin: 15px 5% 0 1%;
  width: 950px; /* 调整为你需要的宽度 */
  height: 55px;
  overflow: hidden;

  p {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}
@media (max-width: 1300px) {
  .limit {
    text-align: left;
    color: #666666;
    line-height: 2;
    font-size: 14px;
    margin: 15px 5% 0 1%;
    width: 850px; /* 调整为你需要的宽度 */
    height: 55px;
    overflow: hidden;

    p {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  .limitContent {
    margin: 1.5% 3% 0 0;
    width: 720px; /* 调整为你需要的宽度 */
    height: 60px;
    font-size: 14px;
    color: #666666;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    line-height: 2;

    p {
      text-align: left;
    }
  }
}

.limit:hover {
  color: var(--main-color);
}

.limitImg {
  margin: 1.5% 2% 0 1%;
  width: 120px; /* 调整为你需要的宽度 */
  height: 80px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 2px rgba(102, 102, 102, 0.8);
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.limitImg:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.detail {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: auto;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.detail1 {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.transborder {
  border-radius: 5px;
  border-color: #2c3e50;
  box-shadow: 1px 1px 1px 1px #2c3e50;
  width: 55%;
  margin-right: 1%;
  min-height: 85vh;
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}

.tag {
  float: right;
  display: flex;
  color: gray;
  font-size: 14px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.scanImg {
  display: inline-block;
  background-image: url("../../assets/scan.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.scan:hover {
  color: var(--main-color);

  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.scan {
  justify-content: center;
  background-size: contain;
  width: 158px;
  height: 20px;
  font-size: 14px;
  align-items: center;
  margin-left: 0.5%;
  display: flex;
}

.agreeImg {
  display: inline-block;
  background-image: url("../../assets/agree.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.agree:hover {
  color: var(--main-color);
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

.agreeImgDeny {
  display: inline-block;
  background-image: url("../../assets/agreeReally.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.agree {
  background-size: contain;
  width: 90px;
  align-items: center;
  font-size: 14px;
  height: 20px;
  margin-left: 0.5%;
  display: flex;
}

//移动端适配

.news1 {
  width: 100%;
  overflow-x: hidden;
  .noData,
  .notLogin {
    text-align: center;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }

  .noData img,
  .notLogin img {
    width: 100%;
    margin: 0 auto;
  }

  .pagination-container {
    bottom: 0;
    font-size: 14px;
    text-align: center;
    background-color: #fff;
  }

  .el-pagination {
    width: 90%;
  }

  .secondery {
    width: 100%;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: space-between; /* 水平分布，两端对齐 */
    align-items: center; /* 垂直居中 */
    font-weight: bold;
    font-size: 14px;
    color: black;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    text-align: left;
    //border: black 1px solid;
  }

  .secondery li {
    width: 100%; /* 填充父元素宽度 */
    /* 其他样式属性... */
  }

  .list {
    margin: 5px 0 60px 0;
    height: auto; /* 高度自动增加以适应内容 */
    padding-top: 5px;
    background-color: white;
    width: 100%;
    //border: black 1px solid;
  }

  .logo {
    background-image: url("../../assets/artical.png");
    background-size: 18px 22px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
  }

  .logo:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }

  .limitTitLe {
    margin-left: 25px;
    font-size: 16px;
    color: #333333;
    width: 45%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .limitTitLe:hover {
    color: var(--main-color);
  }

  .limitTitLe1 {
    display: block;
    margin-left: 25px;
    font-size: 16px;
    width: 50%;
    color: #333333;
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出范围显示省略号 */
    word-wrap: break-word; /* 允许在单词内换行 */
  }

  .limitTitLe1:hover {
    color: var(--main-color);
  }

  .artical {
    width: 90%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 120px;
    margin: auto;
  }

  .artical1 {
    padding-right: 5px;
    width: 90%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 100px;
    margin: auto;
  }

  .buttonStyle1 {
    font-size: 14px;
    color: #333333;
    width: 100%;
    margin: 5px;
    height: 25px;
    padding: 2px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-right: #333333 2px solid;
  }

  .buttonStyle2 {
    font-size: 16px;
    color: var(--main-color);
    width: 100%;
    height: 25px;
    padding: 2px;
    margin: 5px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }

  .buttonStyle3 {
    font-size: 14px;
    color: #333333;
    width: 100%;
    margin: 5px;
    height: 25px;
    text-align: center;
    padding: 2px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-right: #333333 2px solid;
    border-right: #333333 2px solid;
  }

  .buttonStyle4 {
    font-size: 14px;
    color: var(--main-color);
    width: 100%;
    height: 25px;
    padding: 2px;
    text-align: center;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }
  .tag1 {
    display: flex;
    margin-right: 75px;
    margin-top: 75px;
    color: gray;
    line-height: 8px;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    width: 100%;
    justify-content: flex-end; /* 将内容向弹性盒容器的结束边缘对齐（即右对齐）*/
  }

  .subscribe {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    width: 5px;
    margin: -10px 0 0 -15px;
    float: right;
    background-image: url("../../assets/subscribe.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }

  .subscribeDeny {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    margin: -10px 0 0 -15px;
    background-image: url("../../assets/subscribeDeny.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }

  .limit {
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出范围显示省略号 */
    word-wrap: break-word; /* 允许在单词内换行 */
    text-align: justify;
    color: #666666;
    line-height: 1.5;
    font-size: 14px;
    margin: 5px 5% 0 1%;
    width: 94%; /* 调整为你需要的宽度 */
    height: 45px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .limit:hover {
    color: var(--main-color);
  }

  .tag {
    float: right;
    display: flex;
    margin-right: 1%;
    margin-top: 5px;
    color: gray;
    line-height: 8px;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  .scanImg {
    display: inline-block;
    background-image: url("../../assets/scan.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .scan:hover {
    color: var(--main-color);

    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .scan {
    justify-content: center;
    background-size: contain;
    width: 100px;
    height: 15px;
    font-size: 14px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }

  .agreeImg {
    display: inline-block;
    background-image: url("../../assets/agree.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .agree:hover {
    color: var(--main-color);
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .agreeImgDeny {
    display: inline-block;
    background-image: url("../../assets/agreeReally.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .agree {
    background-size: contain;
    width: 80px;
    height: 10px;
    font-size: 14px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }

  .limitImg {
    margin: 1.5% 2% 0 1%;
    width: 105px; /* 调整为你需要的宽度 */
    height: 75px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 2px rgba(102, 102, 102, 0.8);
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .limitImg:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }

  .limitContent {
    line-height: 1.5;
    font-size: 14px;
    margin: 1.5% 3% 0 0;
    position: absolute;
    width: 58%; /* 调整为你需要的宽度 */
    height: 65px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #666666;
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-align: justify;

    p {
      text-align: left;
    }
  }

  .limitContent:hover {
    color: var(--main-color);
  }

  .limitContent1 {
    line-height: 1.5;
    font-size: 14px;
    padding-right: 10px;
    margin: 1.5% 3% 0 0;
    position: absolute;
    width: 60%; /* 调整为你需要的宽度 */
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #666666;
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-align: justify;
    p {
      text-align: left;
    }
  }

  .limitContent1:hover {
    color: var(--main-color);
  }
}

.scienceBox {
  width: 820px;
  margin-right: 20px;
}

.tecP1 {
  width: 580px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #262626;
  transition: color 0.3s;
  padding-top: 2px;
  &:hover{
    color: var(--main-color);
  }
}

.tecP2 {
  margin-top: 10px;
  height: 44px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  color: #8a9099;
  text-overflow: ellipsis;
}

.el-button--mini {
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}

::v-deep .el-tabs__nav-wrap {
  padding: 14px 20px;
  background-color: #fafafa;
}

::v-deep .el-tabs__nav-wrap::after {
  background-color: #fafafa !important;
}

::v-deep .el-tabs__active-bar {
  background-color: #fafafa !important;
}

.new-list-box{
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
}
::v-deep .el-tabs__header{
  margin-bottom: 20px;
}


.label-box {
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;
  // max-width: 8em;
  // text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }
}

.m-list-nav-wrap{
  background-color: #fafafa;
  overflow: hidden;
  .m-list-nav{
    padding: 18px 20px 18px;
    overflow-y:hidden;
    -webkit-overflow-scrolling: touch;
    clip-path: inset(0 0 8px);
    overflow-x: auto;
    white-space: nowrap;
    a{
      font-size: 16px;
      margin-right: 20px;
      &.m-l-nav-active{
        color: var(--main-color);
      }
    }
  }
}

.m-info-wrap{
  padding: 0 20px;
  margin-bottom: 20px;
}
.m-info-box{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .m-info-txt{
    flex: 1;
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    justify-content: space-between;
    .m-info-title{
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      cursor: pointer;
      &:hover{
        color: var(--main-color);
      }
    }
    .m-info-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
      color: #a1a8b3;
    }
  }
  .m-info-img{
    width: 120px;
    height: 70px;
  }

}


</style>

