<template>
  <div>
    <Header></Header>
    <div class="wrapper">

      <div
          v-if="isMobile === false"
          class="totalPage"
      >
        <div class="news">
          <div class="secondery">
            <!--          <li v-for="(seconderyClassfys, index) in seconderyClassfy" :key="index">-->
            <!--            <div style="display: flex">-->
            <!--              <el-button :class="seconderyClassfys.buttonStyle ? 'buttonStyle2' : 'buttonStyle1'" @click="sendSecondery(seconderyClassfys)">-->
            <!--                <i class="el-icon-location"></i>-->
            <!--                <span>{{ seconderyClassfys.title }}</span>-->
            <!--              </el-button>-->
            <!--              <el-button v-if="seconderyClassfys.isSubscribe === 0" class="subscribe"-->
            <!--                         @click="subscribeThis(seconderyClassfys)"></el-button>-->
            <!--              <el-button v-if="seconderyClassfys.isSubscribe === 1" class="subscribeDeny"-->
            <!--                         @click="subscribeThis(seconderyClassfys)"></el-button>-->
            <!--            </div>-->
            <!--          </li>-->
          </div>
          <div class="list" ref="list">
            <div>
              <div style="width: 98%; margin-left: 1%">
                <div
                    v-if="isLoading === true"
                    style="width: 90%; min-height: 45vh"
                >
                  <template v-if="languageSelect === 'zh'">
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      更多数据，等你发现
                    </div>
                  </template>
                  <template v-if="languageSelect === 'en'">
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      More data waiting to be found
                    </div>
                  </template>
                </div>

                <!--        分页-->
                <!--              <div class="pagination-container">-->
                <!--                <el-pagination-->
                <!--                    v-model="page"-->
                <!--                    :current-page="page"-->
                <!--                    @size-change="handleSizeChange"-->
                <!--                    @current-change="handleCurrentChange"-->
                <!--                    :page-sizes="[5, 8, 10, 15]"-->
                <!--                    :page-size="8"-->
                <!--                    layout="sizes, prev, pager, next, jumper"-->
                <!--                    background-->
                <!--                    :total=total>-->
                <!--                </el-pagination>-->
                <!--              </div>-->
              </div>
            </div>
          </div>
          <AiPop @change="showDialog"></AiPop>
        </div>
      </div>
      <div
          v-if="isMobile === true"
          style="width: 100%; margin: 0 auto; overflow-x: hidden"
      >
        <div class="news1">
          <!--        <div class="secondery">-->
          <!--          <li v-for="(seconderyClassfys, index) in seconderyClassfy" :key="index">-->
          <!--            <div style="display: flex">-->
          <!--              <el-button :class="seconderyClassfys.buttonStyle ? 'buttonStyle2' : 'buttonStyle1'" @click="sendSecondery(seconderyClassfys)">-->
          <!--                <i class="el-icon-location"></i>-->
          <!--                <span>{{ seconderyClassfys.title }}</span>-->
          <!--              </el-button>-->
          <!--              <el-button v-if="seconderyClassfys.isSubscribe === 0" class="subscribe"-->
          <!--                         @click="subscribeThis(seconderyClassfys)"></el-button>-->
          <!--              <el-button v-if="seconderyClassfys.isSubscribe === 1" class="subscribeDeny"-->
          <!--                         @click="subscribeThis(seconderyClassfys)"></el-button>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--        </div>-->
          <div class="list" ref="list">
            <div>
              <div style="width: 98%; margin-left: 1%; font-size: 8px">
                <div
                    v-if="isLoading === true"
                    style="width: 100%; min-height: 45vh"
                >
                  <template v-if="languageSelect === 'zh'">
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      更多数据，等你发现
                    </div>
                  </template>
                  <template v-if="languageSelect === 'en'">
                    <div class="notLogin">
                      <img src="@/assets/none.png" alt="" />
                      More data waiting to be found
                    </div>
                  </template>
                </div>

                <!--        分页-->
                <!--              <div class="pagination-container">-->
                <!--                <el-pagination-->
                <!--                    v-model="page"-->
                <!--                    :current-page="page"-->
                <!--                    @size-change="handleSizeChange"-->
                <!--                    @current-change="handleCurrentChange"-->
                <!--                    :page-sizes="[5, 8, 10, 15]"-->
                <!--                    :page-size="8"-->
                <!--                    layout="sizes, prev, pager, next, jumper"-->
                <!--                    background-->
                <!--                    :total=total>-->
                <!--                </el-pagination>-->
                <!--              </div>-->
              </div>
            </div>
          </div>
          <AiPop @change="showDialog"></AiPop>
        </div>
      </div>
    </div>
    <Footer v-if="isMobile === false"></Footer>
    <BottomBanner v-if="isMobile === true">123</BottomBanner>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";

export default {
  name: "DataService",
  components: {
    BottomBanner,
    AiPop,
    Header,
    Footer,
  },
  data() {
    return {
      isMobile: false,
      languageSelect: "",
      dialogVisible: false,
      seconderyClassfy: [],
      isLoading: true,
      userId: "",
      token: "",
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    //   获取新闻二级分类列表
    this.classfy();
    window.scrollTo(0, 0);
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    sendSecondery(index) {
      console.log("Clicked item at index:", index);
      // this.sendSearch()
    },
    // 获取二级分类
    classfy() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 2,
            parentId: 8,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data[0]);
          this.seconderyClassfy = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组

          // this.$notify.success({
          //   title: '提示',
          //   message: '分类查询成功',
          //   showClose: false
          // })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
};
</script>



<style scoped lang="scss">
.totlePage{
  width: 1350px;
  margin: 0 auto
}
@media (max-width: 1300px) {
  .totlePage{
    width: 1220px;
    margin: 0 auto
  }
}
.limitTitLe {
  width: 50%;
  font-size: 22px;
  font-weight: bold;
  margin-top: 1%;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  padding: 10px;
  background-color: #fff;
}

.buttonStyle1 {
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}
.buttonStyle2 {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-color);
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.subscribe {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 7%;
  width: 82%;
  overflow-x: hidden;
}

.secondery {
  width: 18%;
  height: 100%;
  //border: black 1px solid;
}

.list {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  //background-color: white;
  margin-left: 2%;
  width: 80%;
  overflow-x: hidden;
  //border: black 1px solid;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.talk_sub {
  font-size: 5px;
  width: 50%;
  margin-top: 15vh;
  height: 30vh;
  border-radius: 10px;
  background-image: url("../../assets/none.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.talk_word {
  display: flex;
  min-height: 40px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 60%;
  margin-left: 17.5%;
  max-height: 40px;
  padding: 0px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  border: rgb(84, 82, 82) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.cascader {
  width: 20%;
  border-color: white !important;
}

.secondery {
  //font-weight: bold;
  //font-size: 20px;
  //
  //color: black;
  width: 160px;
  height: 55px;
  //border-right: #2c3e50;
  //border: none;
  //border-radius: 5px;
  //margin-top: 5px;
  //box-shadow: 4px 2px var(--main-color);
}

.secondery :active {
  color: var(--main-color);
}

.talkcontent {
  min-height: 22px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 40%;
  max-height: 22px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 2%;
  margin-top: 0.5%;
  //border: black solid 1px; background-color: white; /* 半透明白色，alpha值为0.5 */
}

::v-deep .el-cascader .el-input__inner:hover {
  border: none;
}

.logo {
  background-image: url("../../assets/artical.png");
  width: 30px;
  height: 30px;
  margin: 1.5% 2% 0 1.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}

.artical {
  width: 90%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 150px;
  margin-left: 5%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical:hover {
  background-color: rgba(240, 76, 76, 0.05);
  //transform: translateY(-5px);
}

.detailContent {
  margin-left: 7.5%;
  width: 85%;
  min-height: 94vh;
  height: auto;
}

.custom-cascader .el-cascader-input input {
  border: none !important;
}

.custom-cascader .el-cascader-menu {
  border: 1px solid #fff !important; /* 设置下拉菜单边框为白色 */
}

.limitContent {
  margin: 1.5% 3% 0 0;
  width: 80%; /* 调整为你需要的宽度 */
  height: 80px;
  color: gray;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    text-align: left;
  }
}

.limit {
  text-align: left;
  color: gray;
  margin: 15px 5% 0 3%;
  width: 58vw; /* 调整为你需要的宽度 */
  height: 55px;
  overflow: hidden;

  p {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.limitImg {
  margin: 0.5% 3% 0 3%;
  width: 10%; /* 调整为你需要的宽度 */
  height: 65px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: auto;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.detail1 {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.transborder {
  border-radius: 5px;
  border-color: #2c3e50;
  box-shadow: 0 5px 0 0 #2c3e50;
  width: 55%;
  margin-right: 1%;
  min-height: 85vh;
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}

.tag {
  display: flex;
  color: gray;
  font-size: 20px;
  border-radius: 5px;
}

.tag1 {
  display: inline-block;
  background-size: contain;
  background-color: rgba(240, 76, 76, 0.3);
  border-radius: 5px;
  margin-left: 1%;
}

.scanImg {
  display: inline-block;
  background-image: url("../../assets/scan.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 23px;
}

.scan {
  background-size: contain;
  width: 100px;
  height: 15px;
  margin-left: 1%;
  display: flex;
}

.agreeImg {
  display: inline-block;
  background-image: url("../../assets/agree.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 23px;
}

.agree {
  display: inline-block;
  background-size: contain;
  width: 100px;
  height: 23px;
  margin-left: 1%;
  display: flex;
}

.tag2 {
  display: inline-block;
  background-size: contain;
  background-color: rgba(44, 62, 80, 0.3);
  border-radius: 5px;
  margin-left: 1%;
}

.news1 {
  width: 100%;
  overflow-x: hidden;
  .list {
    min-height: 45vh;
    height: auto;
    padding-top: 5px;
    width: 100%;
    overflow-x: hidden;
  }
  .noData,
  .notLogin {
    text-align: center;
    color: #666666;
    font-size: 16px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .noData img,
  .notLogin img {
    width: 100%;
    margin: 0 auto;
  }
}
</style>

