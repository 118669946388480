<template>
  <div>
    <div v-if="primaryTypeid==='2'">
      <Paper></Paper>
    </div>
    <div v-if="primaryTypeid==='3'">
      <Commercial :primaryTypeid="primaryTypeid"></Commercial>
    </div>
    <div v-if="primaryTypeid==='1'" >
      <Science :primaryTypeid="primaryTypeid"></Science>
    </div>
    <div v-if="primaryTypeid==='4'" >
      <!-- <NewsList :primaryTypeid="primaryTypeid"></NewsList> -->
      <Science :primaryTypeid="primaryTypeid"></Science>
    </div>
    <div v-if="primaryTypeid==='8'">
      <DataService></DataService>
    </div>
  </div>
</template>

<script>

import Paper from "@/views/PrimaryClassfy/Paper.vue";
import Commercial from "@/views/PrimaryClassfy/Commercial.vue";
import Science from "@/views/PrimaryClassfy/Science.vue";
import DataService from "@/views/PrimaryClassfy/DataService.vue";
// import NewsList from "@/views/PrimaryClassfy/NewsList.vue";

export default {
  name: "",
  components: {
    // NewsList,
    DataService,
    Science,
    Commercial,
    Paper
  },
  data() {
    return {
      primaryTypeid: null,
      routeMap: {
        technology: '1',
        news: '4',
        report: '2',
        opportunity: '3'
      }
    }

  },
  created() {
    console.log(this.$route)
    this.primaryTypeid = this.routeMap[this.$route.name.toLowerCase()]
  },
  methods: {},
  // watch: {
  //   '$route.query.id': function () {
  //     this.primaryTypeid = this.$route.query.id
  //   },
  // },

};
</script>


<style scoped lang="scss">

</style>

